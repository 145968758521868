import React, {Component} from 'react';
import ProjectPiece from './projectPiece';
import Fade from 'react-reveal/Fade';

class Projects extends Component {

    constructor(props) {
        super(props);
        this.state = {
            projectPieces: [
                {
                    image: '/assets/images/portfolio/fundastand.jpg',
                    filters: ['Vue', 'Javascript'],
                    name: 'Fundastand',
                    link: 'http://fundastand.adearlife.com'
                },
                // {
                //     image: '/assets/images/portfolio/blabber.jpg',
                //     filters: ['Node', 'React', 'Javascript'],
                //     name: 'Blabbber',
                //     link: 'https://blabbber.com'
                // },
                {
                    image: '/assets/images/portfolio/adearlife.jpg',
                    filters: ['React', 'Javascript'],
                    name: 'A Dear Life',
                    link: 'http://adearlife.com'
                },
                // {
                //     image: '/assets/images/portfolio/thumb-1.jpg',
                //     filters: ['Node', 'React', 'Javascript'],
                //     name: 'Kuajiri'
                // },
                // {
                //     image: '/assets/images/portfolio/titanictattoos.jpg',
                //     filters: ['React', 'Node', 'GraphQL', "Javascript"],
                //     name: 'Titanic tattoos',
                //     link: 'http://www.titanictattoos.com'
                // },
                // {
                //     image: '/assets/images/portfolio/schoolbeneaththepines.jpg',
                //     filters: ['HTML'],
                //     name: 'School beneath the pines',
                //     link : 'http://schoolbeneaththepines.com'
                // },
                {
                    image: '/assets/images/portfolio/greatdogescape.jpg',
                    filters: ['HTML'],
                    name: 'Great dog escape',
                    link: 'http://greatdogescape.com'
                },
                {
                    image: '/assets/images/portfolio/sass-loaders.png',
                    filters: ['HTML'],
                    name: 'SASS Loaders',
                    link: 'http://andrewdear.github.io/SassLoaders/index.html'
                },
            ],
            currentFilter: 'All'
        }
    }

    renderFilterButtons() {
        const filters = [];
        this.state.projectPieces.forEach((piece) => {
            piece.filters.forEach((filter) => {
                filters.indexOf(filter) < 0 ? filters.push(filter) : null
            })
        });
        filters.unshift('All');
        return filters.map((filter) => {
            return <div
                className={`projectFilterButton ${this.state.currentFilter === filter ? 'current' : ''}`}
                key={filter}
                onClick={() => {
                    this.setState({currentFilter: filter})
                }}
            >
                {filter}
            </div>
        });
    }

    projectsToShow() {
        if (this.state.currentFilter === 'All') {
            return this.state.projectPieces;
        }
        return this.state.projectPieces.filter((project) => {
            return project.filters.indexOf(this.state.currentFilter)  >= 0;
        })
    }

    render() {
        return <div className='projects'>
            <div className='container'>
                <div className='row'>
                    <div className='col-md-12'>
                        <Fade bottom>
                        <h2>Personal Projects</h2>
                        </Fade>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-12 filters'>
                        <Fade bottom>
                        {this.renderFilterButtons()}
                        </Fade>
                    </div>
                </div>
                <Fade bottom>
                <div className='row gallery'>
                    {this.projectsToShow().map((piece, index) => {
                        return <ProjectPiece key={index} piece={piece}/>

                    })}
                </div>
                </Fade>
            </div>
        </div>
    }
}


export default Projects;