import React from 'react';
import PropTypes from 'prop-types';

const Footer = (props) => {
    return (
        <div className='footer'>
            <div className='container'>
                <div className='row'>
                    <div className='col-6'>
                        <p>Andrew Dear &copy;{new Date().getFullYear()}</p>
                    </div>
                    <div className='col-6 iconContainer'>
                        <a href='https://www.linkedin.com/in/andrew-dear/' target='_blank'><i className="fab fa-linkedin" /></a>
                        <a href='https://www.facebook.com/andrew.dearr' target='_blank'><i className="fab fa-facebook" /></a>
                        {/*<i className="fab fa-instagram" />*/}
                    </div>
                </div>
            </div>
        </div>
    )
};

Footer.propTypes = {};

export default Footer