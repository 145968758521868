import React from 'react';
import {Radar, RadarChart, PolarGrid, Legend, PolarAngleAxis, PolarRadiusAxis, ResponsiveContainer} from 'recharts';

const data = [
    {language: 'React/Next.js', A: 90},
    {language: 'Vue', A: 80},
    {language: 'Node.js', A: 75},
    {language: 'Typescript', A: 90},
    {language: 'Angular 5+', A: 50},
    {language: 'AWS CDK', A: 70},
    {language: 'Golang', A: 65},
    {language: 'GraphQL', A: 60},
];

const SkillsChart = () => {
        return (
            <ResponsiveContainer height={500}>
            <RadarChart data={data}>
                <PolarGrid />
                {/*outer line with names*/}
                <PolarAngleAxis dataKey="language" tick={{stroke: '#fff'}}  />
                {/*// line showing numbers*/}
                <PolarRadiusAxis/>
                <Radar name="Mike" dataKey="A" stroke="#8884d8" fill="#BEFF07" fillOpacity={0.6} tick={false}/>
            </RadarChart>
            </ResponsiveContainer>
        );

};

export default SkillsChart;