import React from 'react';
const styles = {fill: "#EFEFEF", stroke: "#EEEEEE", strokeMiterlimit: "10"};

export const headParts = [
    <g id="Layer_52">
        <path style={styles} d="M238.552,435.417c0.027,0.026,0.053,0.067,0.096,0.094
			c-0.286,0.178-0.57,0.326-0.843,0.463c-0.013-0.191-0.013-0.367-0.026-0.557C238.022,435.431,238.295,435.431,238.552,435.417z"/>
        <path style={styles} d="M237.805,437.974c0.178,3.784,0.339,7.582,0.517,11.367
			c-10.662-1.289-15.816-7.773-16.644-18.354C226.371,435.409,230.495,441.649,237.805,437.974z"/>
        <path style={styles} d="M237.778,437.417c0.013,0.189,0.013,0.365,0.026,0.557
			c-7.31,3.676-11.434-2.564-16.127-6.986C227.523,431.882,231.661,437.227,237.778,437.417z"/>
    </g>,
    <g id="Layer_51">
        <path style={styles} d="M259.999,430.987c-0.843,10.838-6.226,17.375-17.417,18.434
            c-0.069-0.095-0.151-0.176-0.204-0.271c0.148-2.509,0.313-5.046,0.461-7.555c-0.326-1.289,0.066-2.591,0.514-3.893
            C250.991,441.935,255.195,435.518,259.999,430.987z"/>
        <path style={styles} d="M259.999,430.987c-4.803,4.53-9.008,10.947-16.646,6.715
            c0.042-0.096,0.069-0.191,0.109-0.271C249.812,437.472,254.016,431.896,259.999,430.987z"/>
    </g>,
    <g id="Layer_50">
        <path style={styles} d="M242.674,430.837c0.125,0.108,0.23,0.217,0.339,0.339
			c-1.192,1.601-2.196,3.256-1.084,5.643c0.379,0.259,0.745,0.489,1.1,0.691c-0.991,1.072-1.738,2.144-2.186,3.229
			c-0.461-1.086-1.206-2.157-2.196-3.229c0.352-0.202,0.718-0.433,1.099-0.691c1.627-3.5-1.29-5.439-2.646-7.908
			C238.96,429.55,240.804,430.187,242.674,430.837z"/>
        <path style={styles} d="M243.014,431.177c1.955,2.062,1.181,4.151,0.448,6.254
			c-0.109,0-0.217-0.014-0.339-0.014c-0.026,0.026-0.053,0.067-0.093,0.094c-0.355-0.202-0.721-0.433-1.1-0.691
			C240.817,434.433,241.821,432.778,243.014,431.177z"/>
        <path style={styles} d="M237.1,428.911c1.356,2.469,4.273,4.408,2.646,7.908
			c-0.381,0.259-0.747,0.489-1.099,0.691c-0.042-0.026-0.069-0.067-0.096-0.094c-0.257,0.014-0.53,0.014-0.773,0
			c-0.543-2.958-1.083-5.887-1.613-8.83C236.478,428.694,236.788,428.803,237.1,428.911z"/>
        <path style={styles} d="M240.844,438.74c-0.84,1.912-0.853,3.853,0,5.846
			c-0.392,0.937-0.975,1.886-1.749,2.835c-0.26-0.026-0.517-0.054-0.774-0.08c-0.177-3.785-0.339-7.583-0.517-11.367
			c0.273-0.137,0.557-0.285,0.843-0.463C239.638,436.583,240.383,437.654,240.844,438.74z"/>
        <path style={styles} d="M240.844,438.74c0.829,1.912,0.842,3.853,0,5.846
			C239.991,442.593,240.004,440.652,240.844,438.74z"/>
        <path style={styles} d="M242.377,447.149c-0.013,0.149-0.013,0.3-0.026,0.448
			c-1.343-0.055-2.687-0.122-4.03-0.177c0-0.026,0-0.054,0-0.08c0.257,0.026,0.514,0.054,0.774,0.08
			c0.773-0.949,1.356-1.898,1.749-2.835C241.183,445.428,241.699,446.282,242.377,447.149z"/>
        <path style={styles} d="M243.029,435.511c0.109,0.082,0.215,0.137,0.323,0.191
			c-0.448,1.302-0.84,2.604-0.514,3.893c-0.148,2.509-0.312,5.046-0.461,7.555c-0.678-0.867-1.195-1.722-1.534-2.563
			c0.842-1.993,0.829-3.934,0-5.846C241.292,437.654,242.039,436.583,243.029,435.511z"/>
    </g>,
    <g id="Layer_49">
        <path style={styles} d="M236.938,428.587c0.053,0.107,0.109,0.216,0.162,0.324
            c-0.312-0.108-0.623-0.217-0.935-0.324c0.53,2.943,1.07,5.872,1.613,8.83c-6.117-0.19-10.255-5.535-16.101-6.43
            c-1.817-4.354-3.635-8.708-5.454-13.063c0.149-0.217,0.299-0.434,0.435-0.651c0.217,0.299,0.448,0.597,0.678,0.896
            c-0.013-0.637-0.041-1.261-0.096-1.872c0.083-0.149,0.163-0.311,0.245-0.461c0.176,0.394,0.353,0.787,0.516,1.194
            c0.868,1.275,1.75,2.55,2.617,3.825c5.154-0.176,6.132-4.545,6.769-7.976c0.759-3.976,2.062-7.745,3.542-11.421
            C232.922,410.491,234.93,419.538,236.938,428.587z"/>
        <path style={styles} d="M217.337,418.168c-0.23-0.298-0.461-0.596-0.678-0.896
            c0.204-0.325,0.393-0.65,0.583-0.977C217.296,416.907,217.324,417.531,217.337,418.168z"/>
    </g>,
    <g id="Layer_48">
        <path style={styles} d="M265.125,418.711c-1.709,4.096-3.418,8.18-5.126,12.276
			c-5.982,0.909-10.187,6.484-16.537,6.443c0.733-2.103,1.507-4.191-0.448-6.254c0.612-0.854,1.29-1.681,1.725-2.59
			c2.305-10.391,4.61-20.795,6.904-31.172c2.157,4.978,4.218,10.011,5.249,15.464c0.639,3.431,1.613,7.8,6.756,7.976
			C264.134,420.135,264.637,419.431,265.125,418.711z"/>
    </g>,
    <g id="Layer_47">
        <path style={styles} d="M228.175,389.045c0.909,4.137,1.831,8.274,2.754,12.412
            c-1.48,3.676-2.782,7.446-3.542,11.421c-0.637,3.431-1.615,7.8-6.769,7.976c-0.868-1.275-1.75-2.55-2.617-3.825
            c-0.163-0.407-0.339-0.8-0.516-1.194C222.084,407.316,221.352,396.615,228.175,389.045z"/>
    </g>,
    <g id="Layer_46">
        <path style={styles} d="M271.729,382.602c0.109,1.194,0.53,2.415,1.412,3.648
			c-1.113,9.943-8.07,18.014-9.848,27.768c-3.635-8.18-3.447-17.933-9.794-24.973v-0.013l2.891-7
			c2.238-0.095,4.148-0.678,5.874-1.561c-0.029,0.095-0.042,0.204-0.056,0.312C264.568,383.959,268.299,382.778,271.729,382.602z"/>
        <path style={styles} d="M265.451,417.924c-0.108,0.257-0.217,0.529-0.326,0.787
			c-0.488,0.719-0.991,1.424-1.479,2.143c-5.143-0.176-6.117-4.545-6.756-7.976c-1.031-5.454-3.092-10.486-5.249-15.464
			c0.623-2.793,1.235-5.575,1.857-8.369c6.348,7.04,6.16,16.793,9.794,24.973c-0.257,1.343-0.408,2.726-0.421,4.15
			c0.503-0.65,1.004-1.315,1.504-1.967C264.704,416.785,265.056,417.368,265.451,417.924z"/>
        <path style={styles} d="M264.377,416.201c2.308-3.011,4.613-6.022,6.92-9.047
			c-1.804,3.228-3.595,6.458-5.021,9.875c-0.379,0.556-0.758,1.126-1.152,1.682c0.108-0.258,0.217-0.53,0.326-0.787
			C265.056,417.368,264.704,416.785,264.377,416.201z"/>
    </g>,
    <g id="Layer_45">
        <path style={styles} d="M224.933,379.4c0.286,0.557-0.718,1.737-1.112,2.632
            c-0.909-0.041-1.763-0.163-2.578-0.339c0.285-0.258,0.57-0.556,0.828-0.909c-0.746-6.24-4.83-10.812-8.03-15.653
            c-5.643-8.532-9.333-18.041-14.134-26.981c1.492,0.245,3.106,0.462,3.322,1.113C208.004,354.008,218.083,365.836,224.933,379.4z"
        />
        <path style={styles} d="M222.071,380.785c-0.258,0.353-0.543,0.65-0.828,0.909
            c-6.524-1.493-10.132-7.406-15.354-11.489c-0.191,4.544,3.458,8.803,2.441,13.28c-0.746,0.475-1.424,1.207-1.98,2.278
            c-1.452,3.894-0.191,7.528,1.302,11.028c2.671,6.293,6.552,11.964,9.427,18.162c0.068,0.435,0.122,0.882,0.162,1.343
            c-0.189,0.326-0.379,0.652-0.583,0.977c-7.352-9.604-13.321-16.521-20.713-26.084c-2.076-2.659-2.903-5.278-1.669-8.641
            c5.033-13.551,4.463-30.412,4.205-44.681c0.421,0.122,0.91,0.203,1.425,0.283c4.802,8.94,8.491,18.449,14.134,26.981
            C217.241,369.973,221.325,374.544,222.071,380.785z"/>
        <path style={styles} d="M217.08,414.953c-2.875-6.198-6.756-11.869-9.427-18.162
            c-1.493-3.5-2.754-7.135-1.302-11.028c0.556-1.071,1.234-1.804,1.98-2.278c-0.204,0.909-0.611,1.831-1.276,2.766
            C208.208,396.519,215.587,404.78,217.08,414.953z"/>
    </g>,
    <g id="Layer_44">
        <path style={styles} d="M287.289,379.835c1.235,3.363,0.408,5.982-1.669,8.641
			c-4.801,6.212-9.562,12.438-14.322,18.678c1.897-3.391,3.81-6.782,5.331-10.363c1.491-3.5,2.752-7.135,1.301-11.028
			c-1.505-2.862-3.77-3.269-6.2-3.161c-0.405-4.151,2.755-8.152,2.591-12.397c-4.271,3.337-7.461,7.907-12.057,10.268
			c0.827-6.076,4.827-10.58,7.974-15.341c4.56-6.891,7.842-14.419,11.451-21.786C281.741,355.745,282.894,368.021,287.289,379.835z"
        />
        <path style={styles} d="M281.728,337.88c-0.026,1.831-0.056,3.648-0.04,5.465
			c-3.608,7.367-6.891,14.895-11.451,21.786c-3.147,4.761-7.147,9.265-7.974,15.341c-1.725,0.882-3.635,1.465-5.874,1.561v-0.013
			c-0.408-0.896-1.399-2.062-1.113-2.619c6.851-13.564,16.929-25.393,21.703-40.137C277.264,338.368,280.085,338.314,281.728,337.88
			z"/>
        <path style={styles} d="M277.929,385.763c1.452,3.894,0.19,7.528-1.301,11.028
			c-1.521,3.581-3.434,6.972-5.331,10.363c-2.308,3.025-4.613,6.036-6.92,9.047c-0.405-0.705-0.757-1.438-1.083-2.183
			c1.778-9.753,8.735-17.825,9.848-27.768c-0.882-1.233-1.303-2.455-1.412-3.648C274.159,382.494,276.424,382.9,277.929,385.763z"/>
        <path style={styles} d="M264.377,416.201c-0.5,0.652-1.001,1.317-1.504,1.967
			c0.013-1.424,0.164-2.807,0.421-4.15C263.62,414.764,263.972,415.497,264.377,416.201z"/>
    </g>,
    <g id="Layer_43">
        <path style={styles} d="M242.025,367.07c0.04,0.109,0.079,0.231,0.122,0.353
            c-2.456,7.569-5.142,15.084-6.485,22.966c-1.518,3.716-3.227,7.352-4.732,11.069c-0.924-4.138-1.846-8.275-2.754-12.412
            c-6.823,7.569-6.091,18.271-10.689,26.79c-0.136-0.298-0.271-0.597-0.407-0.882c-1.493-10.173-8.871-18.434-10.025-28.703
            c0.665-0.935,1.072-1.857,1.276-2.766c3.743-2.428,9.386,1.248,12.913-1.791c0.815,0.176,1.669,0.298,2.578,0.339
            c0.394-0.896,1.398-2.076,1.112-2.632c-6.85-13.564-16.928-25.393-21.704-40.137c-0.216-0.65-1.831-0.868-3.322-1.113
            c-0.245-0.474-0.502-0.948-0.76-1.409c0.041-1.356,0.067-2.728,0.095-4.084c10.852,0.801,21.486,2.686,32.011,5.21
            c0.082,0.026,0.178,0.053,0.26,0.066c0.013,0.015,0.04,0.015,0.066,0.028c1.112,0.542,4.165,3.311,6.703,8.993
            c-0.694,2.943-0.151,6.172,0.636,9.454C239.8,360.003,240.886,363.544,242.025,367.07z"/>
        <path style={styles} d="M242.091,364.67c-0.04,0.772-0.066,1.572-0.066,2.4
            c-1.139-3.526-2.226-7.067-3.108-10.662c-0.787-3.282-1.33-6.511-0.636-9.454C240.166,351.173,241.782,356.993,242.091,364.67z"/>
    </g>,
    <g id="Layer_42">
        <path style={styles} d="M285.037,332.657c0.026,1.356,0.056,2.728,0.082,4.084
			c-1.208,2.169-2.334,4.38-3.431,6.604c-0.016-1.817,0.013-3.635,0.04-5.465c-1.643,0.434-4.464,0.487-4.748,1.383
			c-4.774,14.745-14.852,26.573-21.703,40.137c-0.286,0.557,0.705,1.724,1.113,2.619v0.013l-2.891,7v0.013
			c-0.623,2.794-1.234,5.576-1.857,8.369c-1.017-2.333-2.048-4.667-3.025-7.026c-1.343-7.882-4.029-15.396-6.47-22.966
			c1.179-3.648,2.318-7.297,3.214-11.015c0.813-3.337,1.356-6.632,0.596-9.617c2.482-5.465,5.426-8.193,6.581-8.802
			C263.225,335.398,274.024,333.472,285.037,332.657z"/>
        <path style={styles} d="M245.957,346.791c0.76,2.985,0.217,6.28-0.596,9.617
			c-0.896,3.717-2.035,7.367-3.214,11.015c0-0.948-0.013-1.858-0.056-2.752C242.404,356.884,244.047,351.023,245.957,346.791z"/>
    </g>,
    <g id="Layer_41">
        <path style={styles} d="M242.147,341.067c2.144,1.695,3.296,3.635,3.81,5.724
            c-1.91,4.232-3.553,10.093-3.866,17.879c-0.31-7.678-1.926-13.497-3.81-17.716C238.769,344.798,239.935,342.791,242.147,341.067z"
        />
        <path style={styles} d="M252.538,337.989c-1.155,0.609-4.098,3.337-6.581,8.802
            c-0.514-2.089-1.667-4.028-3.81-5.724c0.23-0.189,0.475-0.366,0.731-0.543C246.095,339.63,249.308,338.774,252.538,337.989z"/>
        <path style={styles} d="M241.4,340.524c0.257,0.177,0.503,0.367,0.747,0.543
            c-2.212,1.723-3.378,3.73-3.865,5.887c-2.538-5.683-5.59-8.451-6.703-8.993C234.861,338.748,238.131,339.616,241.4,340.524z"/>
        <path style={styles} d="M252.917,337.867c-0.082,0-0.217,0.04-0.379,0.122
            c-1.155,0.609-4.098,3.337-6.581,8.802c-1.91,4.232-3.553,10.093-3.866,17.879c-0.04,0.772-0.066,1.572-0.066,2.4"/>
        <path style={styles} d="M231.579,337.961c1.112,0.542,4.165,3.311,6.703,8.993
            c1.884,4.219,3.5,10.039,3.81,17.716c0.042,0.894,0.056,1.804,0.056,2.752"/>
    </g>,
    <g id="Layer_40">
        <path style={styles} d="M206.733,325.085c0.57,0.529,1.139,1.044,1.709,1.574
			c-3.785,0.135-7.839-0.517-11.204,0.731c-10.513,3.88-18.692,0.869-25.814-6.375c11.571,3.309,23.101,6.809,35.391,4.583
			C206.787,325.424,206.761,325.26,206.733,325.085z"/>
        <path style={styles} d="M206.733,325.085c0.028,0.175,0.054,0.339,0.082,0.514
			c-12.29,2.226-23.82-1.274-35.391-4.583c-0.433-0.421-0.854-0.882-1.274-1.343c12.643-3.852,23.86,3.214,35.81,4.707
			C206.218,324.609,206.476,324.854,206.733,325.085z"/>
    </g>,
    <g id="Layer_39">
        <path style={styles} d="M206.733,325.085c-0.257-0.23-0.515-0.476-0.773-0.706
            c-11.95-1.493-23.167-8.559-35.81-4.707c0.42,0.461,0.841,0.922,1.274,1.343c-0.135-0.041-0.285-0.082-0.42-0.122
            c-3.228-1.615-6.578-3.039-9.006-5.847c10.567,0.813,20.726-1.763,30.899-3.948c3.202-0.691,7.338-1.248,7.027-6.293
            c-0.204-0.054-0.394-0.108-0.598-0.163c0.204-0.149,0.394-0.326,0.57-0.53C205.241,310.123,205.472,317.76,206.733,325.085z"/>
        <path style={styles} d="M199.924,304.806c0.311,5.046-3.826,5.602-7.027,6.293
            c-10.174,2.185-20.333,4.761-30.899,3.948c-0.191-0.23-0.394-0.474-0.584-0.732c9.197-7.041,20.7-5.006,30.968-7.8
            c2.334-0.624,4.938-0.23,6.945-1.872C199.531,304.697,199.72,304.751,199.924,304.806z"/>
    </g>,
    <g id="Layer_38">
        <path style={styles} d="M135.18,287.157c21.377,5.834,42.77,11.652,64.147,17.485
			c-2.007,1.642-4.611,1.248-6.945,1.872c-10.268,2.793-21.771,0.759-30.968,7.8c0.19,0.258,0.393,0.502,0.584,0.732
			c-0.191-0.013-0.367-0.026-0.556-0.041C149.844,308.943,143.562,297.508,135.18,287.157z"/>
        <path style={styles} d="M161.998,315.047c-0.191-0.013-0.367-0.026-0.556-0.041
			c-11.598-6.063-17.879-17.498-26.262-27.849c21.377,5.834,42.77,11.652,64.147,17.485"/>
    </g>,
    <g id="Layer_37">
        <path style={styles} d="M312.788,315.353c-0.421,0.461-0.843,0.909-1.274,1.342
            c-11.572,3.311-23.102,9.51-35.393,7.299c0.029-0.177,0.056-0.339,0.082-0.517c0.257-0.23,0.517-0.474,0.774-0.705
            C288.915,321.281,300.145,311.5,312.788,315.353z"/>
        <path style={styles} d="M311.513,316.695c-7.122,7.23-15.303,12.969-25.815,9.09
            c-3.365-1.249-7.418-0.597-11.204-0.734c0.569-0.528,1.139-1.044,1.708-1.574c-0.026,0.178-0.053,0.339-0.082,0.517
            C288.411,326.205,299.941,320.005,311.513,316.695z"/>
    </g>,
    <g id="Layer_36">
        <path style={styles} d="M290.041,309.493c10.157,2.185,20.304,2.049,30.873,1.234
			c-2.403,2.794-5.752,4.232-8.981,5.846c-0.135,0.041-0.283,0.082-0.418,0.122c0.432-0.433,0.853-0.881,1.274-1.342
			c-12.643-3.854-23.873,5.928-35.811,7.419c-0.257,0.23-0.517,0.474-0.774,0.705c1.261-7.311,1.492-14.96,6.838-20.969
			c0.175,0.203,0.365,0.379,0.569,0.528c-0.204,0.054-0.395,0.109-0.599,0.163C282.688,308.245,286.837,308.801,290.041,309.493z"/>
        <path style={styles} d="M321.509,309.995c-0.191,0.257-0.379,0.502-0.596,0.732
			c-10.568,0.815-20.715,0.95-30.873-1.234c-3.203-0.691-7.352-1.248-7.029-6.293c0.204-0.054,0.395-0.109,0.599-0.163
			c2.005,1.642,4.613,1.248,6.944,1.872C300.823,307.703,312.327,302.956,321.509,309.995z"/>
    </g>,
    <g id="Layer_35">
        <path style={styles} d="M347.756,285.538c-8.382,10.363-14.664,19.071-26.26,25.148
            c-0.191,0.013-0.395,0.027-0.583,0.041c0.217-0.23,0.405-0.475,0.596-0.732c-9.183-7.039-20.686-2.292-30.955-5.086
            c-2.331-0.624-4.938-0.23-6.944-1.872C305.748,296.997,326.993,291.216,347.756,285.538z"/>
        <path style={styles} d="M320.913,310.727c0.188-0.014,0.392-0.028,0.583-0.041
            c11.596-6.078,17.877-14.786,26.26-25.148c-20.763,5.679-42.008,11.459-64.146,17.499"/>
    </g>
];

export const rightAntlerParts = [
    <g id="Layer_3_copy_2">
        <g>
            <path style={styles} d="M389.729,14.043l6.449,27.869l0.039,0.117l9.344,31.602
				l0.019,0.05c0.132,0.321,0.264,0.626,0.415,0.93c0.299-0.187,0.79-0.051,0.79,0.354c0,0.506,0,1.014,0,1.518
				c0.188,0.457,0.32,0.895,0.413,1.301c-0.132-0.252-0.263-0.506-0.413-0.776c0,0.067,0,0.151,0,0.219
				c0,0.101-0.021,0.186-0.075,0.252l1.504,5.048c6.806,2.786,13.462,5.402,19.366,7.596l0.677-1.467L389.729,14.043z"/>
            <line style={styles} x1="428.254" y1="88.656" x2="389.729" y2="14.043"/>
            <line style={styles} x1="389.729" y1="14.043" x2="396.178" y2="41.913"/>
        </g>
    </g>,
    <g id="Layer_2_copy_2">
        <g>
        <path style={styles} d="M427.333,86.772l-0.607,1.467
    c9.014,3.732,16.088,6.229,18.519,6.229l-11.868-70.966L427.333,86.772z"/>
    <line style={styles} x1="445.245" y1="94.469" x2="433.377" y2="23.503"/>
        <line style={styles} x1="433.377" y1="23.503" x2="427.333" y2="86.772"/>
        </g>
</g>,
<g id="Layer_4_copy_2">
    <g>
        <path style={styles} d="M354.494,35.243l9.893,20.122l2.768,2.6
				c3.46,2.364,9.858,5.892,17.59,9.825l11.816,0.692L354.494,35.243z"/>
        <line style={styles} x1="396.561" y1="68.481" x2="354.494" y2="35.243"/>
        <line style={styles} x1="354.494" y1="35.243" x2="364.387" y2="55.365"/>
    </g>
    </g>,
    <g id="Layer_5_copy_2">
        <g>
            <line style={styles} x1="443.32" y1="122.576" x2="445.245" y2="93.811"/>
            <line style={styles} x1="419.603" y1="141.06" x2="419.856" y2="102.605"/>
            <line style={styles} x1="407.297" y1="80.644" x2="410.96" y2="86.114"/>
            <line style={styles} x1="407.162" y1="81.15" x2="412.395" y2="88.208"/>
            <line style={styles} x1="419.856" y1="102.605" x2="398.4" y2="87.598"/>
            <g>
                <path style={styles} d="M405.271,75.293c0.017,0.033,0.051,0.066,0.101,0.1
					c0-0.016,0-0.051,0-0.067C405.338,75.326,405.306,75.31,405.271,75.293z"/>
                <path style={styles} d="M405.355,75.715l0.035,0.049
					c0.017-0.101,0.017-0.201,0.033-0.303c-0.017-0.033-0.033-0.049-0.051-0.068C405.355,75.494,405.355,75.613,405.355,75.715z"/>
                <path style={styles} d="M405.372,75.326c0,0.017,0,0.051,0,0.067
					c0.018,0.019,0.035,0.035,0.051,0.068c0-0.033,0-0.084,0-0.119C405.406,75.326,405.39,75.326,405.372,75.326z"/>
                <path style={styles} d="M405.406,74.398c-0.017,0.305-0.017,0.625-0.035,0.928
					c0.018,0,0.035,0,0.051,0.017c0.035-0.388,0.051-0.775,0.084-1.197C405.439,74.212,405.406,74.314,405.406,74.398z"/>
                <path style={styles} d="M405.39,75.765c-0.035,0.508-0.051,0.996-0.051,1.452
					c0.49-0.151,0.812-0.387,0.996-0.708l-0.084-0.084L405.39,75.765z"/>
                <path style={styles} d="M405.372,78.162l0.946,1.014l-0.557-0.828v-0.016
					C405.642,78.28,405.507,78.231,405.372,78.162z"/>
                <path style={styles} d="M405.423,75.461c-0.017,0.102-0.017,0.203-0.033,0.303
					l0.861,0.66l-0.051-0.271C405.929,75.935,405.66,75.699,405.423,75.461z"/>
                <path style={styles} d="M405.423,75.343c0,0.035,0,0.085,0,0.119
					c0.237,0.237,0.506,0.473,0.776,0.692l-0.168-0.76C405.828,75.394,405.609,75.377,405.423,75.343z"/>
                <path style={styles} d="M405.423,75.343c0.186,0.035,0.405,0.051,0.608,0.051
					l-0.303-1.469c-0.085,0.051-0.169,0.119-0.221,0.203c0,0.018,0,0.018,0,0.018C405.474,74.568,405.458,74.955,405.423,75.343z"/>
                <path style={styles} d="M405.558,73.132c0,0.338-0.033,0.674-0.051,0.996
					c0.051-0.084,0.135-0.152,0.221-0.203L405.558,73.132z"/>
                <path style={styles} d="M405.558,73.064v0.067l0.169,0.793
					c0.168-0.066,0.388-0.049,0.523,0.119c0.051,0.068,0.1,0.152,0.151,0.219c0.017-0.117,0.035-0.219,0.051-0.338
					c-0.017,0,0-0.016,0-0.016c-0.051-0.238-0.103-0.455-0.152-0.692C406.064,73.183,405.812,73.132,405.558,73.064z"/>
                <path style={styles} d="M406.199,74.195c0.119,0.422,0.219,0.844,0.287,1.25
					c0.018,0.017,0.018,0.017,0.018,0.017c0.033,0,0.068,0,0.101,0c0.017-0.472-0.051-0.996-0.151-1.537
					c-0.017,0.119-0.035,0.221-0.051,0.338c-0.051-0.067-0.101-0.151-0.151-0.219c-0.135-0.168-0.354-0.185-0.523-0.119l0.303,1.469
					c0.135,0.019,0.271,0.035,0.388,0.051C406.318,75.04,406.25,74.617,406.199,74.195z"/>
                <path style={styles} d="M405.761,78.348l0.557,0.828l1.047,1.115
					c0.051-0.322,0.084-0.625,0.101-0.945c0.051,0.017,0.119,0.017,0.169,0.017c-0.018-0.069-0.051-0.119-0.067-0.187
					C406.976,78.905,406.369,78.635,405.761,78.348z"/>
                <path style={styles} d="M406.031,75.394l0.168,0.76
					c0.067,0.084,0.135,0.151,0.219,0.203c0.051-0.101,0.085-0.219,0.119-0.354c0-0.016,0.017-0.016,0-0.033
					c-0.051-0.17-0.101-0.34-0.119-0.524C406.301,75.428,406.166,75.412,406.031,75.394z"/>
                <path style={styles} d="M406.418,76.356c-0.084-0.051-0.152-0.119-0.219-0.203
					l0.051,0.271l0.084,0.084C406.369,76.457,406.385,76.408,406.418,76.356z"/>
                <path style={styles} d="M406.301,73.216c0.049,0.237,0.101,0.455,0.152,0.692
					c0.016-0.203,0.051-0.422,0.067-0.642v-0.018C406.453,73.249,406.385,73.232,406.301,73.216z"/>
                <path style={styles} d="M406.318,79.175l0.979,1.469
					c0.033-0.119,0.051-0.237,0.068-0.354L406.318,79.175z"/>
                <path style={styles} d="M406.199,74.195c0.051,0.422,0.119,0.844,0.219,1.25
					c0.018,0,0.051,0,0.067,0C406.418,75.04,406.318,74.617,406.199,74.195z"/>
                <path style={styles} d="M406.418,76.356c-0.033,0.051-0.049,0.101-0.084,0.152
					l0.371,0.287c0-0.051-0.016-0.117-0.016-0.186C406.604,76.543,406.521,76.457,406.418,76.356z"/>
                <path style={styles} d="M406.418,75.445c0.018,0.185,0.067,0.354,0.119,0.524
					c0.017-0.053,0.035-0.119,0.035-0.17v-0.018c-0.018-0.101-0.051-0.201-0.068-0.32c0,0,0,0-0.018-0.017
					C406.469,75.445,406.437,75.445,406.418,75.445z"/>
                <path style={styles} d="M406.74,75.494c0.101,0.019,0.203,0.053,0.303,0.103
					c0-0.776-0.049-1.537-0.151-2.262c-0.119-0.033-0.236-0.051-0.371-0.068c-0.017,0.219-0.051,0.438-0.067,0.642
					c0,0-0.017,0.016,0,0.016c0.101,0.541,0.168,1.065,0.151,1.537C406.656,75.478,406.689,75.478,406.74,75.494z"/>
                <path style={styles} d="M406.537,76.002c-0.033,0.135-0.068,0.254-0.119,0.354
					c0.102,0.101,0.186,0.187,0.271,0.252c0-0.017,0-0.05-0.017-0.084c0,0,0-0.016,0-0.033
					C406.621,76.338,406.572,76.17,406.537,76.002z"/>
                <path style={styles} d="M406.504,75.461c0.017,0.119,0.05,0.219,0.068,0.32
					c0.017-0.101,0.033-0.201,0.033-0.32C406.572,75.461,406.537,75.461,406.504,75.461z"/>
                <path style={styles} d="M406.521,73.249v0.018c0.135,0.017,0.252,0.035,0.371,0.068
					c-0.033-0.237-0.068-0.473-0.135-0.709c-0.119,0.049-0.203,0.185-0.219,0.371C406.537,73.081,406.521,73.167,406.521,73.249z"/>
                <path style={styles} d="M406.537,75.969c0.017,0.017,0,0.017,0,0.033
					c0.035,0.168,0.084,0.336,0.135,0.49c-0.017-0.219-0.068-0.457-0.101-0.692C406.572,75.851,406.554,75.917,406.537,75.969z"/>
                <path style={styles} d="M406.572,75.781v0.018c0.033,0.236,0.084,0.473,0.101,0.692
					c0,0.017,0,0.033,0,0.033c0.017,0.035,0.017,0.068,0.033,0.101c0.018,0.019,0.018,0.019,0.035,0.035c0-0.354,0-0.76,0-1.166
					c-0.051-0.016-0.084-0.016-0.135-0.033C406.604,75.58,406.588,75.681,406.572,75.781z"/>
                <path style={styles} d="M406.672,76.524c0.017,0.035,0.017,0.068,0.017,0.084
					c0.016,0.016,0.016,0.016,0.016,0.016C406.689,76.592,406.689,76.559,406.672,76.524z"/>
                <path style={styles} d="M406.705,76.625c0,0,0,0-0.016-0.016
					c0,0.069,0.016,0.135,0.016,0.186l0.035,0.035c0-0.017,0-0.051,0-0.069C406.723,76.711,406.723,76.678,406.705,76.625z"/>
                <path style={styles} d="M406.705,76.795c0.018,0.219,0.035,0.422,0.035,0.641
					c0-0.151,0-0.354,0-0.606L406.705,76.795z"/>
                <path style={styles} d="M406.705,76.625c0.018,0.053,0.018,0.086,0.035,0.135
					c0-0.033,0-0.066,0-0.101C406.723,76.644,406.723,76.644,406.705,76.625z"/>
                <path style={styles} d="M406.74,76.76c0,0.018,0,0.053,0,0.069l0.017,0.017
					C406.756,76.813,406.756,76.795,406.74,76.76z"/>
                <path style={styles} d="M406.74,76.66c0,0.035,0,0.068,0,0.101
					c0.017,0.035,0.017,0.053,0.017,0.086l1.317,1.029c0-0.371,0-0.758-0.017-1.131c-0.051-0.135-0.119-0.252-0.187-0.371
					c-0.168-0.303-0.371-0.506-0.59-0.641c-0.035,0.371-0.103,0.742-0.254,1.198C406.942,76.846,406.84,76.76,406.74,76.66z"/>
                <path style={styles} d="M407.043,75.597c-0.101-0.05-0.203-0.084-0.303-0.103
					c0,0.406,0,0.811,0,1.166c0.101,0.101,0.203,0.187,0.287,0.271c0.151-0.457,0.219-0.828,0.254-1.198
					C407.213,75.681,407.127,75.629,407.043,75.597z"/>
                <path style={styles} d="M408.074,77.875l-1.317-1.029
					c0.254,0.793,0.524,1.569,0.811,2.329c0.101,0.051,0.185,0.084,0.271,0.117c0.133-0.049,0.236-0.185,0.236-0.387
					C408.074,78.567,408.074,78.213,408.074,77.875z"/>
                <path style={styles} d="M406.976,71.139l0.271,0.997
					c0.016-0.035,0.051-0.067,0.102-0.067C407.213,71.766,407.094,71.46,406.976,71.139z"/>
                <path style={styles} d="M407.313,74.72c0.017,0.185,0.168,0.235,0.236,0.387
					c0.018,0,0,0.018,0,0.018c0.018,0,0.018,0,0.035,0c0-0.457,0-1.065,0-1.571c-0.203-0.101-0.438-0.17-0.692-0.219
					c0.102,0.725,0.151,1.485,0.151,2.262c0.084,0.033,0.17,0.084,0.238,0.135c0.016-0.084,0.016-0.168,0.016-0.254h-0.016
					c0-0.033,0.016-0.084,0.016-0.119c0.017-0.066,0.017-0.134,0-0.218C407.313,75.006,407.313,74.871,407.313,74.72z"/>
                <path style={styles} d="M407.246,72.136l0.338,1.283c0-0.051,0-0.101,0-0.152
					c0.151,0.473,0.32,0.861,0.473,1.199c0-0.168,0-0.354,0-0.524c-0.203-0.606-0.457-1.231-0.709-1.873
					C407.297,72.069,407.262,72.102,407.246,72.136z"/>
                <path style={styles} d="M407.297,75.359c0,0.034-0.016,0.085-0.016,0.119h0.016
					v-0.017C407.297,75.428,407.313,75.394,407.297,75.359z"/>
                <path style={styles} d="M407.297,75.478c0,0.085,0,0.169-0.016,0.254
					c0.219,0.135,0.422,0.338,0.59,0.641c0.068,0.119,0.135,0.236,0.187,0.371c0.017-0.319,0-0.623,0-0.944
					c0-0.457-0.49-0.557-0.76-0.338V75.478z"/>
                <path style={styles} d="M408.428,78.145c0.033,0.49,0.051,0.979,0.051,1.453
					c0.287,0.135,0.574,0.27,0.86,0.387l-1.35-5.048c-0.085,0.135-0.254,0.205-0.405,0.188c0,0.033,0,0.066,0,0.101
					c0-0.035-0.017-0.067-0.035-0.101c0,0,0.018-0.018,0-0.018c-0.084,0-0.152-0.035-0.236-0.084c0,0.033,0,0.084-0.017,0.119
					c0.017,0.084,0.017,0.152,0,0.218c0.017,0.034,0,0.069,0,0.102c0.271-0.219,0.76-0.119,0.76,0.338c0,0.321,0.017,0.625,0,0.944
					C408.242,77.166,408.36,77.657,408.428,78.145z"/>
                <path style={styles} d="M407.365,80.29c-0.017,0.117-0.035,0.235-0.068,0.354
					l3.663,5.47l-1.621-6.129c-0.287-0.117-0.574-0.252-0.86-0.387c0,0.674-0.051,1.317-0.051,1.839L407.365,80.29z"/>
                <path style={styles} d="M407.313,74.72c0,0.151,0,0.287-0.017,0.422
					c0.017-0.035,0.017-0.086,0.017-0.119c0.084,0.049,0.152,0.084,0.236,0.084C407.481,74.955,407.33,74.904,407.313,74.72z"/>
                <path style={styles} d="M407.365,80.29l1.063,1.147
					c-0.254-0.674-0.524-1.366-0.793-2.075c-0.051,0-0.119,0-0.169-0.017C407.449,79.665,407.416,79.968,407.365,80.29z"/>
                <path style={styles} d="M408.057,72.423c0-0.406-0.44-0.541-0.709-0.354
					c0.252,0.641,0.506,1.266,0.709,1.873C408.057,73.437,408.057,72.929,408.057,72.423z"/>
                <path style={styles} d="M407.549,75.125c0.018,0.033,0.035,0.066,0.035,0.101
					c0-0.035,0-0.067,0-0.101C407.567,75.125,407.567,75.125,407.549,75.125z"/>
                <path style={styles} d="M407.567,79.175c0.017,0.068,0.049,0.117,0.067,0.187
					c0.068,0,0.135-0.017,0.203-0.069C407.752,79.259,407.668,79.227,407.567,79.175z"/>
                <path style={styles} d="M407.584,73.554c0,0.506,0,1.114,0,1.571
					c0.151,0.017,0.32-0.053,0.405-0.188l-0.405-1.518C407.584,73.47,407.584,73.503,407.584,73.554z"/>
                <path style={styles} d="M407.584,73.267c0,0.051,0,0.101,0,0.152l0.405,1.518
					c0.05-0.066,0.068-0.151,0.068-0.252c0-0.068,0-0.152,0-0.219C407.904,74.128,407.735,73.74,407.584,73.267z"/>
                <path style={styles} d="M407.838,79.292c-0.068,0.053-0.135,0.069-0.203,0.069
					c0.269,0.709,0.539,1.401,0.793,2.075c0-0.522,0.051-1.166,0.051-1.839C408.276,79.497,408.057,79.411,407.838,79.292z"/>
                <path style={styles} d="M407.838,79.292c0.219,0.119,0.438,0.205,0.641,0.305
					c0-0.473-0.018-0.963-0.051-1.453l-0.354-0.27c0,0.338,0,0.692,0,1.03C408.074,79.108,407.971,79.243,407.838,79.292z"/>
                <path style={styles} d="M408.057,73.941c0,0.17,0,0.356,0,0.524
					c0.135,0.27,0.252,0.524,0.371,0.776C408.344,74.836,408.225,74.398,408.057,73.941z"/>
                <path style={styles} d="M408.057,76.744c0.017,0.373,0.017,0.76,0.017,1.131
					l0.354,0.27C408.36,77.657,408.242,77.166,408.057,76.744z"/>
                <path style={styles} d="M445.245,93.811c-2.431,0-9.504-2.498-18.519-6.23
					c-5.301-2.194-11.275-4.81-17.387-7.596c-0.287-0.117-0.574-0.252-0.86-0.387c-0.203-0.101-0.422-0.187-0.641-0.305"/>
                <line style={styles} x1="408.428" y1="78.145" x2="408.074" y2="77.875"/>
                <line style={styles} x1="408.479" y1="78.196" x2="408.428" y2="78.145"/>
                <line style={styles} x1="405.558" y1="73.132" x2="405.728" y2="73.925"/>
                <polyline style={styles} points="407.989,74.937 409.339,79.985 410.96,86.114
					"/>
                <line style={styles} x1="406.976" y1="71.139" x2="407.246" y2="72.136"/>
                <path style={styles} d="M406.301,73.216c0.084,0.016,0.152,0.033,0.219,0.033"/>
                <path style={styles} d="M405.558,73.064c0.253,0.067,0.506,0.119,0.743,0.152"/>
                <line style={styles} x1="405.542" y1="73.048" x2="405.558" y2="73.064"/>
                <path style={styles} d="M405.338,72.997c0.068,0.018,0.135,0.034,0.203,0.051"/>
                <path style={styles} d="M407.635,79.362c0.269,0.709,0.539,1.401,0.793,2.075
					c0-0.522,0.051-1.166,0.051-1.839c0-0.473-0.018-0.963-0.051-1.453c-0.067-0.488-0.186-0.979-0.371-1.401"/>
                <line style={styles} x1="405.558" y1="73.132" x2="405.423" y2="74.82"/>
                <path style={styles} d="M405.507,74.128"/>
                <line style={styles} x1="408.428" y1="81.437" x2="407.365" y2="80.29"/>
                <path style={styles} d="M407.549,75.107c-0.084,0-0.152-0.035-0.236-0.084
					c0,0.033,0,0.084-0.017,0.119"/>
                <path style={styles} d="M407.584,75.125c-0.017,0-0.017,0-0.035,0"/>
                <path style={styles} d="M407.584,75.242v-0.017c0-0.035,0-0.067,0-0.101
					c0-0.457,0-1.065,0-1.571c0-0.051,0-0.084,0-0.135s0-0.101,0-0.152c0.151,0.473,0.32,0.861,0.473,1.199
					c0.135,0.27,0.252,0.524,0.371,0.776c-0.084-0.406-0.203-0.844-0.371-1.301c-0.203-0.606-0.457-1.231-0.709-1.873
					c-0.135-0.303-0.254-0.608-0.373-0.93l-0.017-0.05c-0.067-0.203-0.151-0.405-0.219-0.625"/>
                <path style={styles} d="M407.549,75.125c0.018,0.033,0.035,0.066,0.035,0.101"/>
                <path style={styles} d="M407.313,74.685c0,0.018,0,0.035,0,0.035
					c0.017,0.185,0.168,0.235,0.236,0.387"/>
                <path style={styles} d="M407.297,75.142c0.017-0.135,0.017-0.271,0.017-0.422"/>
                <path style={styles} d="M407.162,81.15c-0.203,0.271-0.692,0.305-0.844-0.035
					c-0.102-0.201-0.168-0.371-0.254-0.574c-0.017,0.051-0.033,0.086-0.084,0.119c-0.101,0.103-0.236,0.135-0.371,0.152
					c-0.035,0.186-0.035,0.373-0.067,0.574c0,0.016,0,0.035,0,0.051c0,0.035,0,0.084-0.017,0.119
					c-0.086,0.556-0.963,0.404-0.963-0.119c0-0.574,0.017-1.131,0.033-1.688c-0.59,0.506-1.113,1.014-1.586,1.688
					c-0.422,0.592-1.401,0-0.963-0.574c0.709-0.944,1.503-1.739,2.465-2.415c0.051-0.033,0.102-0.049,0.151-0.066
					c0.018-0.169,0.018-0.356,0.035-0.524c-6.871-3.208-13.707-6.515-19.75-9.604c-7.731-3.933-14.129-7.461-17.589-9.825
					L398.4,87.598l21.456,15.008l-0.253,38.455c7.36-4.761,19.986-8.846,22.957-17.911c0.102-0.32,2.406,4.394,2.76,4.427
					l-0.075-33.765c-2.431,0-9.504-2.498-18.519-6.23c-5.301-2.194-11.275-4.81-17.387-7.596l1.621,6.129l-3.663-5.47
					c-0.016,0.119-0.035,0.236-0.067,0.354C407.213,81.05,407.195,81.116,407.162,81.15z"/>
                <path style={styles} d="M393.1,70.178c-0.271,0-0.557,0-0.828-0.016
					c-1.096-0.422-2.227-0.71-3.393-0.625c1.03,0.524,2.194,0.625,3.393,0.625c0.238,0.101,0.473,0.203,0.709,0.321
					C393.049,70.381,393.1,70.278,393.1,70.178z M400.207,74.449c0.035,0.033,0.051,0.084,0.067,0.119
					c0.303,0.472,0.59,0.979,0.844,1.518c-0.187-0.473-0.422-0.912-0.692-1.333c-0.017-0.017-0.017-0.033-0.017-0.033
					c-0.035-0.051-0.068-0.103-0.102-0.152c-0.304-0.491-0.523-0.862-0.877-1.217c0.068,0.103,0.152,0.203,0.22,0.304h-0.017
					C399.819,73.925,400.004,74.179,400.207,74.449z M398.822,72.559c0.051,0.1,0.102,0.219,0.153,0.321
					c-1.436-1.571-3.175-2.634-5.167-3.698c0.844,0.574,1.671,1.317,2.364,2.126c-0.354-0.422-0.743-0.792-1.166-1.131
					c0.406,0.422,0.625,1.047,1.047,1.485c-0.641-0.438-1.485-0.641-2.329-1.063c0.86,0.674,1.856,0.961,2.87,1.25h0.016
					c0.152,0.186,0.287,0.404,0.422,0.625c0.016,0,0.033,0.017,0.016,0.033c-0.033,0.033-0.067,0.051-0.101,0.051
					c0.033,0.051,0.068,0.1,0.117,0.151c0.153,0.219,0.305,0.473,0.473,0.641c-0.033-0.067-0.067-0.119-0.119-0.185
					c0.541,0.607,1.132,1.197,1.587,1.654c-0.219-0.422-0.625-1.047-0.828-1.672c0.422,0.422,1.047,1.047,1.453,1.453
					c-0.236-0.238-0.422-0.574-0.608-0.877c0.354,0.439,0.744,0.877,1.047,1.316c-0.371-0.709-0.709-1.401-1.063-2.11
					c0.187,0.168,0.338,0.354,0.49,0.557C399.295,73.2,399.076,72.896,398.822,72.559z M404.73,77.469
					c0,0.119-0.017,0.254-0.033,0.389c-6.871-3.208-13.707-6.515-19.75-9.604l11.816,0.692h0.017l0.035,0.033l2.262,2.43
					l0.016,0.018L404.73,77.469z"/>
                <path style={styles} d="M392.272,70.162c-1.096-0.422-2.227-0.71-3.393-0.625
					C389.91,70.061,391.074,70.162,392.272,70.162z"/>
                <path style={styles} d="M393.1,70.178c-0.271,0-0.557,0-0.828-0.016
					c0.238,0.101,0.473,0.203,0.709,0.321C393.049,70.381,393.1,70.278,393.1,70.178z"/>
                <path style={styles} d="M396.054,71.663c-0.641-0.438-1.485-0.641-2.329-1.063
					c0.86,0.674,1.856,0.961,2.87,1.25c0-0.035-0.019-0.051-0.035-0.068c-0.017-0.033-0.051-0.067-0.084-0.102
					c-0.033-0.066-0.067-0.119-0.135-0.168c-0.051-0.068-0.101-0.135-0.168-0.203c-0.354-0.422-0.743-0.792-1.166-1.131
					C395.413,70.6,395.632,71.225,396.054,71.663z"/>
                <path style={styles} d="M399.008,74.82c-0.455-0.457-1.047-1.047-1.587-1.654
					v-0.018c0,0,0,0-0.017-0.017c-0.084-0.219-0.219-0.422-0.338-0.625c0-0.017,0.018-0.017,0.018-0.017
					c0.084-0.067,0.152-0.203,0.236-0.422c-0.236-0.068-0.455-0.135-0.692-0.219h-0.017c0-0.035-0.016-0.051-0.051-0.068
					c-0.017-0.033-0.051-0.067-0.084-0.102c-0.033-0.066-0.067-0.119-0.135-0.168c-0.051-0.068-0.101-0.135-0.168-0.203
					c-0.692-0.809-1.52-1.553-2.364-2.126c1.991,1.063,3.73,2.126,5.167,3.698c0.016,0.017,0.016,0.033,0.033,0.049
					c0.354,0.709,0.692,1.401,1.063,2.11c-0.303-0.438-0.692-0.877-1.047-1.316c0.186,0.304,0.373,0.64,0.608,0.877
					c-0.406-0.406-1.03-1.031-1.453-1.453C398.384,73.773,398.789,74.398,399.008,74.82z M398.754,73.351
					c0.051,0.086,0.119,0.168,0.187,0.254C398.89,73.52,398.84,73.437,398.754,73.351z"/>
                <path style={styles} d="M396.561,71.782c0.016,0.017,0.035,0.033,0.035,0.068h0.016
					C396.611,71.815,396.595,71.798,396.561,71.782z"/>
                <path style={styles} d="M396.628,71.85h-0.017c0.152,0.186,0.287,0.404,0.422,0.625
					c0.016,0.017,0.033,0.017,0.051,0.017C396.949,72.271,396.798,72.052,396.628,71.85z"/>
                <path style={styles} d="M396.628,71.85c0.17,0.203,0.321,0.422,0.457,0.641
					c0.084-0.067,0.152-0.203,0.236-0.422C397.084,72.001,396.865,71.934,396.628,71.85z"/>
                <path style={styles} d="M396.814,68.979l2.262,2.43
					c-0.035-0.252-0.101-0.504-0.135-0.758h-0.017L396.814,68.979z"/>
                <path style={styles} d="M396.949,72.559c0.033,0.051,0.068,0.1,0.117,0.151
					c0.102,0.152,0.221,0.287,0.338,0.422c-0.084-0.219-0.219-0.422-0.338-0.625h-0.017
					C397.017,72.54,396.982,72.559,396.949,72.559z"/>
                <path style={styles} d="M397.034,72.474c0.016,0,0.033,0.017,0.016,0.033h0.017
					c0-0.017,0.018-0.017,0.018-0.017C397.066,72.491,397.05,72.491,397.034,72.474z"/>
                <path style={styles} d="M397.404,73.132c-0.117-0.135-0.236-0.27-0.338-0.422
					c0.153,0.219,0.305,0.473,0.473,0.641c-0.033-0.067-0.067-0.119-0.119-0.185v-0.018
					C397.421,73.148,397.421,73.148,397.404,73.132z"/>
                <path style={styles} d="M398.754,73.351c0.051,0.086,0.119,0.168,0.187,0.254
					C398.89,73.52,398.84,73.437,398.754,73.351z"/>
                <path style={styles} d="M398.822,72.559c0.051,0.1,0.102,0.219,0.153,0.321
					c0.016,0.017,0.016,0.033,0.033,0.049c0.187,0.168,0.338,0.354,0.49,0.557C399.295,73.2,399.076,72.896,398.822,72.559z"/>
                <path style={styles} d="M402.485,73.454l-3.544-2.803
					c0.035,0.254,0.101,0.506,0.135,0.758l0.016,0.018l5.638,6.042c0.035-0.185,0.051-0.369,0.085-0.556l-2.296-3.427l-0.016-0.016
					L402.485,73.454z"/>
                <path style={styles} d="M399.43,73.351c0.068,0.103,0.152,0.203,0.22,0.304
					c0.135,0.204,0.287,0.389,0.438,0.608c0.085,0.101,0.153,0.203,0.219,0.305C400.004,74.077,399.785,73.706,399.43,73.351z"/>
                <path style={styles} d="M399.65,73.655h-0.017c0.186,0.27,0.371,0.524,0.574,0.794
					c-0.033-0.067-0.084-0.119-0.119-0.187C399.937,74.043,399.785,73.859,399.65,73.655z"/>
                <path style={styles} d="M400.088,74.263c0.034,0.068,0.085,0.119,0.119,0.187
					c0.035,0.033,0.051,0.084,0.067,0.119c0.051,0.049,0.084,0.101,0.135,0.152c-0.035-0.051-0.068-0.103-0.102-0.152
					C400.242,74.466,400.174,74.363,400.088,74.263z"/>
                <path style={styles} d="M400.41,74.72c-0.051-0.051-0.084-0.103-0.135-0.152
					c0.303,0.472,0.59,0.979,0.844,1.518c-0.187-0.473-0.422-0.912-0.692-1.333C400.41,74.736,400.41,74.72,400.41,74.72z"/>
                <path style={styles} d="M404.511,78.448c-0.961,0.676-1.756,1.471-2.465,2.415
					c-0.438,0.574,0.541,1.166,0.963,0.574c0.473-0.674,0.996-1.182,1.586-1.688c-0.017,0.557-0.033,1.114-0.033,1.688
					c0,0.523,0.877,0.674,0.963,0.119c0.017-0.035,0.017-0.084,0.017-0.119c0-0.016,0-0.035,0-0.051
					c0.033-0.201,0.033-0.388,0.067-0.574c0.135-0.017,0.271-0.049,0.371-0.152c0.051-0.033,0.067-0.067,0.084-0.119
					c0.086,0.203,0.152,0.373,0.254,0.574c0.151,0.339,0.641,0.305,0.844,0.035c0.033-0.035,0.051-0.101,0.068-0.152
					c0.033-0.119,0.051-0.235,0.067-0.354l-0.979-1.469l-0.946-1.014c-0.219-0.101-0.438-0.201-0.674-0.303"/>
                <path style={styles} d="M402.503,73.47l0.016,0.016l1.047,0.828
					c-0.017-0.151-0.035-0.287-0.035-0.438c-0.049-0.017-0.117-0.035-0.168-0.051v0.017c-0.017-0.017-0.051-0.035-0.068-0.051
					c-0.016,0-0.016-0.016-0.034-0.016C403.009,73.671,402.755,73.57,402.503,73.47z"/>
                <path style={styles} d="M402.773,73.384l-0.27,0.086
					c0.252,0.1,0.506,0.201,0.758,0.303C403.077,73.638,402.925,73.52,402.773,73.384z"/>
                <path style={styles} d="M404.613,75.142l-0.776-0.608
					c-0.051,0.152-0.119,0.287-0.187,0.422c-0.051-0.203-0.084-0.422-0.084-0.641l-1.047-0.828l2.296,3.427
					c0.017-0.135,0.033-0.254,0.05-0.389c0.034-0.186,0.135-0.269,0.27-0.303c0-0.051,0-0.084,0-0.135
					c0-0.169,0.084-0.287,0.203-0.371L404.613,75.142z"/>
                <path style={styles} d="M402.773,73.384c0.152,0.135,0.303,0.254,0.488,0.389
					c0.018,0,0.018,0.016,0.034,0.016c0.017,0,0.051,0.017,0.068,0.035c0.051-0.219,0.101-0.44,0.151-0.676L402.773,73.384z"/>
                <path style={styles} d="M403.296,73.79c0.017,0.017,0.051,0.035,0.068,0.051v-0.017
					C403.347,73.806,403.312,73.79,403.296,73.79z"/>
                <path style={styles} d="M403.364,73.824c0.051,0.017,0.119,0.035,0.168,0.051
					c0-0.237,0.018-0.491,0.035-0.744l-0.051,0.017C403.464,73.384,403.415,73.605,403.364,73.824z"/>
                <path style={styles} d="M403.566,73.132c-0.017,0.252-0.035,0.506-0.035,0.744
					c0.152,0.066,0.322,0.117,0.473,0.168c0.017-0.067,0.035-0.135,0.051-0.203h-0.119c0.051-0.051,0.119-0.101,0.151-0.17
					c0.051-0.252,0.084-0.488,0.119-0.742L403.566,73.132z"/>
                <path style={styles} d="M403.532,73.875c0,0.152,0.018,0.287,0.035,0.438
					l0.271,0.219c0.067-0.151,0.117-0.321,0.168-0.49C403.854,73.993,403.684,73.941,403.532,73.875z"/>
                <path style={styles} d="M403.566,74.314c0,0.219,0.033,0.438,0.084,0.641
					c0.068-0.135,0.135-0.27,0.187-0.422L403.566,74.314z"/>
                <path style={styles} d="M404.005,74.043c-0.051,0.168-0.101,0.338-0.168,0.49
					l0.776,0.608c0-0.069,0.017-0.135,0.049-0.221c-0.201-0.217-0.336-0.557-0.404-1.08h-0.203
					C404.04,73.909,404.021,73.976,404.005,74.043z"/>
                <path style={styles} d="M404.089,73.671c-0.033,0.069-0.101,0.119-0.151,0.17h0.119
					C404.073,73.773,404.073,73.724,404.089,73.671z"/>
                <path style={styles} d="M404.056,73.841h0.203
					c-0.018-0.067-0.035-0.135-0.035-0.203c-0.017-0.017-0.017-0.049-0.017-0.084c-0.034,0.051-0.067,0.084-0.119,0.117
					C404.073,73.724,404.073,73.773,404.056,73.841z"/>
                <path style={styles} d="M404.089,73.671c0.051-0.033,0.084-0.066,0.119-0.117
					c0.187-0.236,0.354-0.523,0.49-0.861l-0.254-0.119l0.051,0.271l-0.287,0.084C404.173,73.183,404.14,73.419,404.089,73.671z"/>
                <path style={styles} d="M404.224,73.638c0,0.067,0.017,0.135,0.035,0.203h0.793
					C405.052,73.841,404.376,73.841,404.224,73.638z"/>
                <path style={styles} d="M404.613,75.142l0.725,0.574h0.017
					c0-0.103,0-0.221,0.017-0.322c-0.049-0.034-0.084-0.067-0.101-0.1c-0.236-0.051-0.438-0.168-0.608-0.373
					C404.63,75.006,404.613,75.072,404.613,75.142z"/>
                <path style={styles} d="M404.781,74.685c-0.051,0.084-0.084,0.169-0.119,0.235
					c0.17,0.205,0.373,0.322,0.608,0.373C405.035,75.056,404.866,74.854,404.781,74.685z"/>
                <path style={styles} d="M404.73,77.469c0,0.119-0.017,0.254-0.033,0.389
					c0.236,0.102,0.455,0.203,0.674,0.303L404.73,77.469z"/>
                <path style={styles} d="M404.816,76.914c-0.035,0.187-0.051,0.371-0.085,0.556
					l0.641,0.692c0.135,0.069,0.271,0.119,0.389,0.17L404.816,76.914z"/>
                <path style={styles} d="M406.74,77.436c0-0.219-0.017-0.422-0.035-0.641
					l-0.371-0.287c-0.185,0.321-0.506,0.557-0.996,0.708c0-0.457,0.017-0.944,0.051-1.452l-0.035-0.049h-0.017
					c-0.119,0.084-0.203,0.201-0.203,0.371c0,0.051,0,0.084,0,0.135c-0.135,0.035-0.236,0.117-0.27,0.303
					c-0.017,0.135-0.033,0.254-0.05,0.389l0.945,1.418v0.016c0.608,0.287,1.215,0.558,1.807,0.828
					c-0.287-0.76-0.557-1.536-0.811-2.329l-0.017-0.017C406.74,77.082,406.74,77.285,406.74,77.436z"/>
                <polyline style={styles} points="364.589,55.83 367.357,58.429 398.4,87.598
					"/>
                <path style={styles} d="M404.697,77.858c-6.871-3.208-13.707-6.515-19.75-9.604
					c-7.731-3.933-14.129-7.461-17.589-9.825c-1.772-1.199-2.768-2.11-2.768-2.6"/>
                <line style={styles} x1="398.924" y1="70.651" x2="396.814" y2="68.979"/>
                <line style={styles} x1="402.485" y1="73.454" x2="398.941" y2="70.651"/>
                <polyline style={styles} points="405.338,75.715 404.613,75.142 403.837,74.533
					403.566,74.314 402.52,73.486 				"/>
                <polyline style={styles} points="404.495,72.845 404.208,72.929 403.566,73.132
					403.515,73.148 402.773,73.384 402.503,73.47 				"/>
                <path style={styles} d="M404.208,73.554c0,0.035,0,0.067,0.017,0.084"/>
                <path style={styles} d="M404.259,73.841c-0.018-0.067-0.035-0.135-0.035-0.203"/>
                <path style={styles} d="M405.372,75.326c-0.033,0-0.066-0.017-0.101-0.033
					c-0.236-0.051-0.438-0.168-0.608-0.373c-0.201-0.217-0.336-0.557-0.404-1.08"/>
                <line style={styles} x1="396.814" y1="68.979" x2="396.78" y2="68.946"/>
                <line style={styles} x1="399.076" y1="71.409" x2="396.814" y2="68.979"/>
                <line style={styles} x1="404.73" y1="77.469" x2="399.092" y2="71.427"/>
                <line style={styles} x1="404.697" y1="72.693" x2="405.338" y2="72.997"/>
                <line style={styles} x1="404.443" y1="72.575" x2="404.697" y2="72.693"/>
                <polyline style={styles} points="396.78,68.946 403.702,72.22 403.735,72.237
					404.241,72.474 404.443,72.575 				"/>
                <line style={styles} x1="402.52" y1="73.486" x2="404.816" y2="76.914"/>
                <line style={styles} x1="402.503" y1="73.47" x2="402.52" y2="73.486"/>
                <path style={styles} d="M399.144,71.309c0,0,0.017,0,0.017,0.019
					c0.119,0.049,0.592,0.287,0.828,0.539"/>
                <path style={styles} d="M402.485,73.454c-1.18-0.508-2.378-1.2-3.325-2.126"/>
                <line style={styles} x1="402.503" y1="73.47" x2="402.485" y2="73.454"/>
                <path style={styles} d="M403.262,73.773c-0.252-0.103-0.506-0.203-0.758-0.303"/>
                <path style={styles} d="M404.208,74.111c-0.067-0.018-0.135-0.051-0.203-0.068
					c-0.151-0.051-0.321-0.102-0.473-0.168c-0.049-0.017-0.117-0.035-0.168-0.051c-0.017-0.018-0.051-0.035-0.068-0.035"/>
                <path style={styles} d="M405.052,75.799c0-0.219-0.574-0.084-0.438-0.658
					c0-0.069,0.017-0.135,0.049-0.221c0.035-0.066,0.068-0.151,0.119-0.235c0.084,0.169,0.254,0.371,0.49,0.608
					c0.017,0.033,0.051,0.066,0.101,0.1c0.018,0.019,0.035,0.035,0.051,0.068c0.237,0.237,0.506,0.473,0.776,0.692
					c0.067,0.084,0.135,0.151,0.219,0.203c0.102,0.101,0.186,0.187,0.271,0.252c0.016,0.016,0.016,0.016,0.016,0.016
					c0.018,0.019,0.018,0.019,0.035,0.035c0.101,0.101,0.203,0.187,0.287,0.271c0.151-0.457,0.219-0.828,0.254-1.198
					c0.016-0.084,0.016-0.168,0.016-0.254v-0.017c0-0.033,0.017-0.068,0-0.102"/>
                <path style={styles} d="M403.6,72.794c-0.017,0.119-0.05,0.237-0.084,0.354
					c-0.051,0.236-0.1,0.457-0.151,0.676v0.017c-0.017-0.017-0.051-0.035-0.068-0.051c-0.016,0-1.016-1.016-1.034-1.016
					c-0.185-0.135,0.664-0.254,0.512-0.389c-1.333-1.147-2.196-1.717-3.9-2.207c0,0.152,0.017,0.322,0.051,0.473h0.017
					c0.035,0.254,0.101,0.506,0.135,0.758l0.016,0.018c0.035,0.152,0.051,0.287,0.051,0.439"/>
                <path style={styles} d="M403.735,72.237c-0.033,0.168-0.084,0.338-0.117,0.508"/>
                <polyline style={styles} points="396.78,68.946 396.763,68.946 384.947,68.254
					381.587,68.068 				"/>
                <path style={styles} d="M392.272,70.162c0.271,0.016,0.557,0.016,0.828,0.016
					c0,0.101-0.051,0.203-0.119,0.305c-0.084,0.101-0.203,0.218-0.303,0.336"/>
                <path style={styles} d="M392.272,70.162c-1.096-0.422-2.227-0.71-3.393-0.625
					C389.91,70.061,391.074,70.162,392.272,70.162z"/>
                <path style={styles} d="M397.101,72.507l-0.017-0.017c-0.018,0-0.035,0-0.051-0.017
					c-1.301-0.541-2.651-1.368-4.052-1.991c-0.236-0.119-0.471-0.221-0.709-0.321"/>
                <path style={styles} d="M396.628,71.85c0.17,0.203,0.321,0.422,0.457,0.641"/>
                <path style={styles} d="M396.561,71.782c0.035,0.017,0.051,0.033,0.051,0.068"/>
                <path style={styles} d="M393.725,69.131c0.033,0.016,0.051,0.033,0.084,0.051
					c0.844,0.574,1.671,1.317,2.364,2.126"/>
                <path style={styles} d="M398.976,72.88c-1.436-1.571-3.175-2.634-5.167-3.698"/>
                <path style={styles} d="M399.498,73.486c-0.152-0.203-0.303-0.389-0.49-0.557"/>
                <line style={styles} x1="399.65" y1="73.655" x2="399.633" y2="73.655"/>
                <path style={styles} d="M400.207,74.449c-0.033-0.067-0.084-0.119-0.119-0.187"/>
                <path style={styles} d="M400.088,74.263c0.085,0.101,0.153,0.203,0.219,0.305"/>
                <path style={styles} d="M400.088,74.263c-0.152-0.219-0.304-0.404-0.438-0.608"/>
                <path style={styles} d="M400.308,74.568c-0.304-0.491-0.523-0.862-0.877-1.217
					c0.068,0.103,0.152,0.203,0.22,0.304"/>
                <path style={styles} d="M400.308,74.568c0.034,0.049,0.067,0.101,0.102,0.152"/>
                <path style={styles} d="M400.274,74.568c0.051,0.049,0.084,0.101,0.135,0.152
					c0,0,0,0.016,0.017,0.033c0.27,0.422,0.506,0.86,0.692,1.333C400.865,75.547,400.578,75.04,400.274,74.568"/>
                <path style={styles} d="M400.274,74.568c-0.017-0.035-0.033-0.086-0.067-0.119"/>
                <path style={styles} d="M399.633,73.655c0.186,0.27,0.371,0.524,0.574,0.794"/>
                <path style={styles} d="M399.633,73.655c-0.017-0.033-0.051-0.066-0.084-0.117
					c-0.019-0.018-0.035-0.035-0.051-0.051"/>
                <path style={styles} d="M398.789,72.507c0.017,0.017,0.017,0.033,0.033,0.051
					c0.254,0.338,0.473,0.641,0.676,0.928"/>
                <path style={styles} d="M399.025,73.724c0.354,0.439,0.744,0.877,1.047,1.316
					c-0.371-0.709-0.709-1.401-1.063-2.11c-0.017-0.017-0.017-0.033-0.033-0.049c-0.051-0.102-0.103-0.221-0.153-0.321"/>
                <path style={styles} d="M398.586,73.132c0.051,0.068,0.101,0.152,0.168,0.219
					c0.051,0.086,0.119,0.168,0.187,0.254"/>
                <path style={styles} d="M398.941,73.605c-0.051-0.085-0.101-0.168-0.187-0.254"/>
                <path style={styles} d="M398.941,73.605c0.017,0.033,0.051,0.084,0.084,0.119"/>
                <path style={styles} d="M398.165,73.132l0.016,0.017
					c0.422,0.422,1.047,1.047,1.453,1.453c-0.236-0.238-0.422-0.574-0.608-0.877"/>
                <path style={styles} d="M397.421,73.167c0.541,0.607,1.132,1.197,1.587,1.654
					c-0.219-0.422-0.625-1.047-0.828-1.672"/>
                <path style={styles} d="M397.066,72.71c0.102,0.152,0.221,0.287,0.338,0.422"/>
                <path style={styles} d="M396.898,72.507c0.016,0.017,0.033,0.033,0.051,0.051"/>
                <path style={styles} d="M396.949,72.559c0.033,0.051,0.068,0.1,0.117,0.151"/>
                <path style={styles} d="M397.066,72.507c0.119,0.203,0.254,0.405,0.338,0.625
					c0.017,0.017,0.017,0.017,0.017,0.017v0.018c0.051,0.066,0.085,0.117,0.119,0.185c-0.168-0.168-0.32-0.422-0.473-0.641"/>
                <path style={styles} d="M396.611,71.85c0.152,0.186,0.287,0.404,0.422,0.625
					c0.016,0,0.033,0.017,0.016,0.033"/>
                <path style={styles} d="M396.595,71.85c-1.014-0.289-2.01-0.575-2.87-1.25
					c0.844,0.422,1.688,0.625,2.329,1.063c-0.422-0.438-0.641-1.063-1.047-1.485c0.422,0.338,0.811,0.709,1.166,1.131
					c0.068,0.068,0.117,0.135,0.168,0.203c0.067,0.049,0.102,0.102,0.135,0.168c0.033,0.035,0.068,0.069,0.084,0.102
					C396.577,71.798,396.595,71.815,396.595,71.85"/>
                <line style={styles} x1="396.595" y1="71.85" x2="396.611" y2="71.85"/>
                <line style={styles} x1="396.611" y1="71.85" x2="396.628" y2="71.85"/>
                <path style={styles} d="M396.476,72.71c0.203-0.101,0.354-0.101,0.473-0.151
					c0.033,0,0.068-0.019,0.101-0.051h0.017c0-0.017,0.018-0.017,0.018-0.017c0.084-0.067,0.152-0.203,0.236-0.422
					c-0.236-0.068-0.455-0.135-0.692-0.219"/>
            </g>
        </g>
    </g>,
    <g id="Layer_6_copy_2">
    <g>
    <path style={styles} d="M414.973,167.789c-1.924,3.327-0.169,6.551,3.19,7.563
    c2.718,0.828,6.195,0.017,8.88-0.557c0.354-0.083,0.573,0.271,0.489,0.541l0.202,0.086l0.372-0.811l19.801-22.519v-0.033
    c-0.287,0.25-0.844,0.115-0.86-0.373c-0.186-8.796-2.127-17.59-3.342-26.335c-0.422,0.862-0.945,1.925-1.25,2.348
    c-1.452,2.042-3.478,3.813-5.503,5.283c-4.305,3.172-9.031,5.638-13.589,8.424c0.406,0.541,0.169,1.484-0.708,1.52
    c-0.49,0.017-0.979,0.017-1.469,0.033c0.017,0.103,0.017,0.218-0.034,0.353c-1.113,3.327-2.009,6.703-2.886,10.112
    c-0.946,3.716-0.237,7.58-1.569,11.226C416.29,165.748,415.563,166.777,414.973,167.789z"/>
    <path style={styles} d="M420.038,141.12c-0.541,0.353-0.591,1.098-0.237,1.52
    c0.405-0.491,1.3-0.287,1.384,0.32c0.489-0.016,0.979-0.016,1.469-0.033c0.877-0.036,1.114-0.979,0.708-1.52
    c4.558-2.786,9.285-5.252,13.589-8.424c2.025-1.47,4.051-3.241,5.503-5.283c0.304-0.422,0.828-1.485,1.25-2.348
    c1.215,8.745,3.156,17.539,3.342,26.335c0.017,0.488,0.574,0.623,0.86,0.373c0.084-0.086,0.152-0.221,0.152-0.373
    c-0.118-9.522-3.207-18.773-3.156-28.275c0.016-0.305-0.22-0.658-0.523-0.708c-0.219-0.035-0.422-0.051-0.625-0.068
    c-0.354-0.033-0.659,0.254-0.76,0.574C440.024,132.273,427.397,136.358,420.038,141.12z"/>
    <line style={styles} x1="447.907" y1="152.092" x2="428.106" y2="174.61"/>
    </g>
</g>,
<g id="Layer_16_copy_2">
    <path style={styles} d="M264.549,260.179l-9.774,38.031l23.043-6.667l7.781-5.892
			l1.385-2.077c-0.152,0.135-0.438,0.103-0.522-0.135c-1.891-5.622-7.412-7.597-11.8-10.887
			C270.255,269.259,269.023,263.403,264.549,260.179z"/>
    <path style={styles} d="M265.056,259.249c-0.557-0.353-1.047,0.541-0.506,0.93
    l0.203-0.761L265.056,259.249z"/>
    <path style={styles} d="M264.752,259.417l-0.203,0.761
    c4.474,3.225,5.706,9.08,10.112,12.374c4.389,3.29,9.909,5.265,11.8,10.887c0.084,0.237,0.371,0.27,0.522,0.135
    c0.084-0.066,0.119-0.168,0.084-0.303c-1.67-5.265-6.093-7.276-10.331-10.247c-5.181-3.63-6.464-10.296-11.646-13.758
    c-0.017,0-0.017-0.017-0.035-0.017L264.752,259.417z"/>
    <line style={styles} x1="264.549" y1="260.179" x2="254.775" y2="298.21"/>
    <line style={styles} x1="277.818" y1="291.543" x2="254.775" y2="298.21"/>
    <path style={styles} d="M277.818,291.543"/>
    </g>,
    <g id="Layer_15_copy_2">
        <path style={styles} d="M273.142,254.624l-8.086,4.625
			c0.018,0,0.018,0.017,0.035,0.017c5.183,3.462,6.465,10.128,11.646,13.758c4.238,2.971,8.661,4.982,10.331,10.247l21.406-11.681
			l0.252-0.017c-0.574-0.626-2.11-2.314-2.362-2.314c-0.389,0.069-0.727-0.083-0.862-0.488c-0.674-2.024-0.81-3.749,0.051-5.707
			c1.03-2.38,2.989-3.577,3.612-6.077c-5.013,1.975-10.28,0.59-15.326-1.012c-6.027-1.909-14.519-6.109-19.65-0.389
			C273.582,256.262,272.603,255.299,273.142,254.624z"/>
        <path style={styles} d="M273.142,254.624c-0.539,0.676,0.44,1.639,1.046,0.963
			c5.132-5.721,13.624-1.52,19.65,0.389c5.046,1.603,10.313,2.987,15.326,1.012c-0.623,2.5-2.582,3.696-3.612,6.077
			c-0.861,1.958-0.726,3.683-0.051,5.707c0.135,0.405,0.473,0.557,0.862,0.488c0.252,0,1.789,1.688,2.362,2.314
			c0.084,0.083,0.151,0.152,0.186,0.185c0.287,0.271,0.709-0.151,0.438-0.438c-2.6-2.75-4.372-5.724-1.518-9.182
			c1.602-1.942,2.649-3.646,2.784-6.211c0.035-0.508-0.557-0.778-0.963-0.557c-9.047,4.86-20.863-5.387-30.164-4.171
			c-2.28,0.304-4.406,1.283-6.313,3.376C273.16,254.59,273.142,254.607,273.142,254.624z"/>
        <polyline style={styles} points="273.142,254.624 265.056,259.249 264.752,259.417
			"/>
        <line style={styles} x1="308.687" y1="270.582" x2="287.068" y2="283.271"/>
    </g>,
    <g id="Layer_14_copy_2">
    <path style={styles} d="M279.472,251.096l0.018,0.102
    c9.301-1.216,21.117,9.031,30.164,4.171c0.406-0.221,0.998,0.049,0.963,0.557c-0.135,2.565-1.182,4.27-2.784,6.211
    c-2.854,3.458-4.458,8.121-1.858,10.87l19.126-5.723c-0.625-2.381-1.063-4.843-0.76-7.155l-2.498-19.281L279.472,251.096z"/>
    <line style={styles} x1="324.34" y1="260.129" x2="321.843" y2="240.849"/>
    <polyline style={styles} points="321.843,240.849 279.472,251.096 279.455,251.096
    "/>
    <line style={styles} x1="325.101" y1="267.284" x2="308.687" y2="270.582"/>
    </g>,
    <g id="Layer_13_copy_2">
        <path style={styles} d="M342.268,245.965c-0.084-0.085-0.187-0.119-0.32-0.102
			c-6.146,0.877-11.935,4.761-15.701,9.588c-1.131,1.454-1.704,3.023-1.907,4.678l0.912,7.122l1.587-0.271
			c-1.282-4.827-1.282-8.71,2.414-12.406c3.562-3.561,7.934-6.736,12.896-8.002C342.487,246.49,342.471,246.134,342.268,245.965z"/>
        <path style={styles} d="M329.253,254.574c-3.696,3.696-3.696,7.58-2.414,12.406
			l85.72-14.619c0.017-0.099,0.05-0.201,0.118-0.287l-68.602-5.941l-1.807-0.168c0.203,0.168,0.219,0.524-0.119,0.607
			C337.187,247.838,332.815,251.013,329.253,254.574z"/>
        <path style={styles} d="M326.839,266.98"/>
        <polyline style={styles} points="412.677,252.075 344.075,246.134 342.268,245.965
			"/>
        <path style={styles} d="M326.856,267.083c-0.017-0.033-3.393-0.069-3.393-0.103
			l1.789,0.271"/>
        <path style={styles} d="M324.34,260.129c-0.303,2.312,0.135,4.774,0.76,7.155
			l0.151-0.033L324.34,260.129z"/>
    </g>,
    <g id="Layer_12_copy_2">
    <path style={styles} d="M396.927,238.722c-0.776-0.201-1.096,0.962-0.32,1.2
    c0.101,0.033,0.203,0.082,0.303,0.119c0.135,0.099,0.287,0.168,0.473,0.151c6.447,2.143,11.698,6.06,15.647,11.579
    c0.288-0.151,0.49-0.336,0.642-0.541c-3.106-5.536-8.964-9.891-15.007-11.952c0.354-0.369,0.676-0.792,1.012-1.147
    c0.946-0.979,1.942-1.906,2.938-2.835c1.401-1.268,2.835-2.5,4.355-3.612c-0.168-0.254-0.338-0.508-0.506-0.745
    c-1.688,0.996-3.257,2.229-4.761,3.447c-1.367,1.111-3.714,2.647-4.659,4.372C396.995,238.739,396.962,238.739,396.927,238.722z"
    />
    <path style={styles} d="M399.679,238.132c-0.336,0.356-0.658,0.778-1.012,1.147
    c6.043,2.061,11.9,6.416,15.007,11.952c0.084-0.099,0.152-0.201,0.219-0.303c-0.034-0.103-0.034-0.218,0.068-0.32
    c0.067-0.053,0.101-0.135,0.135-0.205c0.168-0.692,0.034-1.484-0.27-2.278c-0.118-0.32-0.034-0.574,0.151-0.742
    c-0.658-2.414-2.025-5.334-2.363-6.195c-1.266-3.241-2.719-6.567-4.642-9.503c-1.52,1.111-2.955,2.344-4.355,3.612
    C401.621,236.226,400.625,237.153,399.679,238.132z"/>
    <path style={styles} d="M406.179,230.554c0.101,0.115,0.185,0.25,0.287,0.386
    c0.185-0.119,0.371-0.217,0.557-0.32L406.179,230.554z"/>
    <path style={styles} d="M406.465,230.939c0.168,0.237,0.338,0.491,0.506,0.745
    c0.338-0.254,0.658-0.491,1.012-0.709c-0.068-0.102-0.152-0.204-0.22-0.307l-0.742-0.049
    C406.836,230.722,406.65,230.821,406.465,230.939z"/>
    <path style={styles} d="M411.75,252.674c0,0,4.017-2,4-2.017
    c0.202-0.926,0.084-1.958-0.304-2.971c-0.102-0.27-0.304-0.438-0.523-0.508c-0.473-1.906-1.35-3.88-1.604-4.589
    c-1.385-3.765-2.871-8.272-5.335-11.614c-0.354,0.218-0.674,0.455-1.012,0.709c1.923,2.936,3.376,6.263,4.642,9.503
    c0.338,0.861,1.705,3.782,2.363,6.195c-0.185,0.168-0.27,0.422-0.151,0.742c0.304,0.794,0.438,1.586,0.27,2.278
    c-0.034,0.069-0.067,0.152-0.135,0.205c-0.102,0.102-0.102,0.217-0.068,0.32c-0.067,0.102-1.135,1.204-1.219,1.303"/>
    <path style={styles} d="M407.022,230.62l0.742,0.049
    c-0.084-0.115-0.168-0.217-0.252-0.32C407.342,230.435,407.174,230.518,407.022,230.62z"/>
    <path style={styles} d="M408.137,230.03c-0.221,0.102-0.422,0.201-0.625,0.319
    c0.084,0.103,0.168,0.205,0.252,0.32l0.119,0.017l0.676-0.574C408.457,229.997,408.305,229.944,408.137,230.03z"/>
    <path style={styles} d="M407.764,230.669c0.068,0.103,0.152,0.205,0.22,0.307
    c0.153-0.119,0.305-0.237,0.457-0.339l-0.557,0.049L407.764,230.669z"/>
    <path style={styles} d="M407.883,230.686l0.557-0.049
    c0.016,0,0.016-0.017,0.033-0.017c0.203-0.135,0.203-0.373,0.086-0.508L407.883,230.686z"/>
    <path style={styles} d="M406.374,231.742c-0.152,0.102-0.304,0.221-0.457,0.339
    c2.464,3.342,3.95,7.849,5.335,11.614c0.253,0.709,1.131,2.684,1.604,4.589c0.219,0.069,0.422,0.238,0.523,0.508
    c0.388,1.012-0.494,2.044-0.696,2.971l32.702-23.364L406.374,231.742z"/>
    <path style={styles} d="M414.095,250.403c0.168-0.692,0.034-1.484-0.27-2.278
    c-0.118-0.32-0.034-0.574,0.151-0.742c-0.658-2.414-2.025-5.334-2.363-6.195c-1.266-3.241-2.719-6.567-4.642-9.503"/>
    <path style={styles} d="M407.984,230.976c2.464,3.342,3.95,7.849,5.335,11.614
    c0.253,0.709,1.131,2.684,1.604,4.589c0.219,0.069,0.422,0.238,0.523,0.508c0.388,1.012,0.506,2.044,0.304,2.971"/>
    <line style={styles} x1="447.451" y1="227.293" x2="415.75" y2="250.657"/>
    <polyline style={styles} points="407.883,230.686 408.44,230.636 447.451,227.293
    "/>
    </g>,
    <g id="Layer_11_copy_2">
        <path style={styles} d="M327.245,200.035l-4.998,37.172l-0.404,2.954l2.498,19.281
			c0.203-1.655,0.776-3.225,1.907-4.678c3.766-4.827,9.555-8.711,15.701-9.588c0.133-0.017,0.235,0.017,0.32,0.102l1.807,0.168
			l0.86-10.043l-2.262-44.367"/>
        <path style={styles} d="M339.871,195.798c-0.557,0.745-1.098,1.503-1.569,2.314
			c-1.926,3.225-2.84,7.084-2.772,10.984"/>
        <path style={styles} d="M341.897,195.187c0,0.033,0,0.069-0.017,0.085
			c0.033-0.016,0.068-0.053,0.084-0.085C341.948,195.187,341.914,195.187,341.897,195.187z"/>
        <path style={styles} d="M342.774,194.072"/>
        <path style={styles} d="M413.031,251.771c-3.95-5.519-9.2-9.436-15.647-11.579
			c-0.186,0.017-0.338-0.053-0.473-0.151c-0.101-0.037-0.203-0.086-0.303-0.119c-0.776-0.238-0.457-1.401,0.32-1.2
			c0.035,0.017,0.067,0.017,0.119,0.036c0.944-1.724,3.292-3.261,4.659-4.372c1.503-1.218,3.073-2.452,4.761-3.447
			c-0.102-0.135-0.186-0.271-0.287-0.386l-19.65-1.639l27.195-19.06v-0.016c-8.069-1.787-15.936-4.405-24.241-2.786
			c-4.777,0.913-13.183,2.602-15.547,7.481c-0.387,0.791-1.621,0.218-1.401-0.594c0.17-0.606,0.44-1.18,0.795-1.688l-28.394,23.839
			l-0.86,10.042"/>
        <path style={styles} d="M372.534,213.94c-0.219,0.812,1.014,1.385,1.401,0.594
			c2.364-4.879,10.77-6.568,15.547-7.481c8.305-1.619,16.172,0.999,24.241,2.786l-0.49-1.045
			c-8.743-2.331-17.067-4.962-26.148-2.786c-2.919,0.692-8.035,1.79-11.411,4.052l-0.49,0.64l-1.856,1.553
			C372.975,212.76,372.704,213.333,372.534,213.94z"/>
        <path style={styles} d="M373.329,212.252l1.856-1.553l0.49-0.64
			C374.73,210.683,373.919,211.408,373.329,212.252z"/>
        <line style={styles} x1="373.329" y1="212.252" x2="344.935" y2="236.091"/>
        <line style={styles} x1="406.179" y1="230.554" x2="386.528" y2="228.915"/>
        <line style={styles} x1="413.724" y1="209.855" x2="386.528" y2="228.915"/>
        <path style={styles} d="M344.935,236.091"/>
        <line style={styles} x1="327.245" y1="200.723" x2="322.247" y2="237.895"/>
        <line style={styles} x1="321.843" y1="240.849" x2="322.247" y2="237.895"/>
        <path style={styles} d="M413.234,208.793l0.49,1.045h0.018l0.675-0.726
			c-0.034-0.017-0.051-0.033-0.085-0.033C413.96,208.978,413.606,208.896,413.234,208.793z"/>
    </g>,
    <g id="Layer_10_copy_2">
    <path style={styles} d="M294.377,151.264l31.448,46.91
    c0.879,0.475,1.723,1.016,2.532,1.622l12.931-6.989c0.254-0.37,1.485,5.86,1.709-2.147L294.377,151.264z"/>
    <line style={styles} x1="294.377" y1="151.264" x2="325.826" y2="198.174"/>
    <path style={styles} d="M328.358,199.796"/>
    <path style={styles} d="M339.601,194.495l-11.243,5.301
    c2.77,2.024,5.099,4.692,6.719,7.715c0.168-2.298,0.473-4.56,1.047-6.822C336.933,197.481,337.812,197.112,339.601,194.495z"/>
    <path style={styles} d="M327.294,200.37l-0.049,0.353
    c1.518,1.167,3.02,2.331,4.304,3.782c1.383,1.572,2.514,3.261,3.409,5.081c0.035-0.689,0.067-1.381,0.119-2.074
    c-1.621-3.023-3.95-5.691-6.719-7.715L327.294,200.37z"/>
    <path style={styles} d="M325.826,198.174l1.469,2.196l1.063-0.574
    C327.548,199.189,326.705,198.649,325.826,198.174z"/>
    <path style={styles} d="M327.245,200.723"/>
    <path style={styles} d="M335.077,207.511c-0.051,0.692-0.084,1.385-0.119,2.074
    c0.457,0.896,0.86,1.807,1.215,2.77c0.035,0.102,0.102,0.168,0.187,0.204c0.119-0.135,0.186-0.307,0.186-0.524
    c0-0.406-0.017-0.828-0.017-1.25C336.141,209.654,335.667,208.556,335.077,207.511z"/>
    <path style={styles} d="M334.891,212.035c-0.016,0.844,1.047,1.012,1.469,0.524
    c-0.084-0.036-0.152-0.102-0.187-0.204c-0.354-0.963-0.758-1.873-1.215-2.77C334.925,210.396,334.907,211.224,334.891,212.035z"/>
    <path style={styles} d="M336.36,212.559c0.27,0.168,0.692-0.02,0.592-0.422
    c-0.119-0.458-0.254-0.913-0.422-1.352c0,0.422,0.017,0.844,0.017,1.25C336.546,212.252,336.479,212.423,336.36,212.559z"/>
    <path style={styles} d="M341.239,194.814c-0.135,0.151-0.287,0.27-0.44,0.405
    C341.002,195.2,341.188,195.068,341.239,194.814z"/>
    <path style={styles} d="M339.871,196.486c0.101,0.036,0.236,0.017,0.338-0.033
    c0.438-0.238,0.86-0.488,1.231-0.775c-0.269,0.049-0.59-1.791-0.641-2.146C340.496,193.954,340.192,196.064,339.871,196.486z"/>
    </g>,
    <g id="Layer_9_copy_2">
        <g>
            <path style={styles} d="M408.44,230.636l39.011-3.343l21.421-48.126l-60.313,50.945
				c0.117,0.135,0.117,0.373-0.086,0.508C408.457,230.62,408.457,230.636,408.44,230.636z"/>
            <line style={styles} x1="447.451" y1="227.293" x2="468.873" y2="179.167"/>
            <polyline style={styles} points="468.873,179.167 408.559,230.112 407.883,230.686
							"/>
        </g>
    </g>,
    <g id="Layer_8_copy_2">
    <g>
    <path style={styles} d="M406.027,193.261l7.207,15.532
    c0.372,0.103,0.727,0.185,1.097,0.287c0.034,0,0.051,0.016,0.085,0.033l27.397-29.946l-18.788,6.481L406.027,193.261z"/>
    <line style={styles} x1="441.813" y1="179.167" x2="423.025" y2="185.648"/>
    <polyline style={styles} points="441.813,179.167 414.416,209.113 413.741,209.839
    413.724,209.839 			"/>
    </g>
</g>,
<g id="Layer_7_copy_2">
    <g>
        <g>
            <path style={styles} d="M413.403,168.145c-0.489,1.062-0.726,2.7-0.32,3.814
					c2.262,6.162,9.048,5.015,14.18,3.614c0.151-0.037,0.236-0.135,0.27-0.238c0.084-0.27-0.135-0.623-0.489-0.541
					c-2.685,0.574-6.162,1.385-8.88,0.557c-3.359-1.012-5.114-4.236-3.19-7.563c0.59-1.012,1.316-2.041,1.722-3.139
					c1.333-3.646,0.624-7.51,1.569-11.226c0.877-3.409,1.773-6.785,2.886-10.112c0.051-0.135,0.051-0.25,0.034-0.353
					c-0.22,0-0.422,0.017-0.642,0.017c-0.338,0-0.591-0.135-0.743-0.336c-0.067,0.066-0.118,0.152-0.151,0.251
					c-1.857,5.285-3.022,10.669-3.342,16.257C416.121,162.708,414.854,165.056,413.403,168.145z"/>
            <path style={styles} d="M421.185,142.959c-0.084-0.606-0.979-0.811-1.384-0.32
					c0.152,0.201,0.405,0.336,0.743,0.336C420.763,142.976,420.965,142.959,421.185,142.959z"/>
            <polyline style={styles} points="428.106,174.61 427.734,175.421 423.025,185.648
									"/>
            <g>
                <path style={styles} d="M399.543,178.679l-23.869,31.381
						c3.376-2.262,8.491-3.359,11.411-4.052c9.082-2.176,17.405,0.455,26.148,2.786l-7.207-15.532l16.999-7.613l4.709-10.227
						l-0.202-0.086c-0.034,0.103-0.119,0.201-0.27,0.238c-5.132,1.401-11.918,2.548-14.18-3.614
						c-0.406-1.114-0.169-2.753,0.32-3.814c1.452-3.089,2.718-5.437,2.904-8.998c0.32-5.588,1.485-10.973,3.342-16.257
						L399.543,178.679z"/>
                <polyline style={styles} points="399.543,178.679 375.675,210.06 375.185,210.699
											"/>
                <line style={styles} x1="419.649" y1="142.89" x2="399.543" y2="178.679"/>
            </g>
        </g>
        <line style={styles} x1="423.025" y1="185.648" x2="406.027" y2="193.261"/>
    </g>
    </g>
];

export const leftAnterParts = [
    <g id="Layer_3">
        <g>
            <path style={styles} d="M88.381,12.043l-6.449,27.869l-0.039,0.117l-9.344,31.602
				l-0.019,0.05c-0.132,0.321-0.264,0.626-0.415,0.93c-0.299-0.187-0.79-0.051-0.79,0.354c0,0.506,0,1.014,0,1.518
				c-0.188,0.457-0.32,0.895-0.413,1.301c0.132-0.252,0.263-0.506,0.413-0.776c0,0.067,0,0.151,0,0.219
				c0,0.101,0.021,0.186,0.075,0.252l-1.504,5.048c-6.806,2.786-13.462,5.402-19.366,7.596l-0.677-1.467L88.381,12.043z"/>
            <line style={styles} x1="49.855" y1="86.656" x2="88.381" y2="12.043"/>
            <line style={styles} x1="88.381" y1="12.043" x2="81.932" y2="39.913"/>
        </g>
    </g>,
    <g id="Layer_2">
        <g>
        <path style={styles} d="M50.776,84.772l0.607,1.467
    c-9.014,3.732-16.088,6.229-18.519,6.229l11.868-70.966L50.776,84.772z"/>
    <line style={styles} x1="32.865" y1="92.469" x2="44.733" y2="21.503"/>
        <line style={styles} x1="44.733" y1="21.503" x2="50.776" y2="84.772"/>
        </g>
</g>,
<g id="Layer_4">
    <g>
        <path style={styles} d="M123.616,33.243l-9.893,20.122l-2.768,2.6
				c-3.46,2.364-9.858,5.892-17.59,9.825l-11.816,0.692L123.616,33.243z"/>
        <line style={styles} x1="81.549" y1="66.481" x2="123.616" y2="33.243"/>
        <line style={styles} x1="123.616" y1="33.243" x2="113.723" y2="53.365"/>
    </g>
    </g>,
    <g id="Layer_5">
        <g>
            <line style={styles} x1="34.79" y1="120.576" x2="32.865" y2="91.811"/>
            <line style={styles} x1="58.507" y1="139.06" x2="58.254" y2="100.605"/>
            <line style={styles} x1="70.813" y1="78.644" x2="67.15" y2="84.114"/>
            <line style={styles} x1="70.948" y1="79.15" x2="65.715" y2="86.208"/>
            <line style={styles} x1="58.254" y1="100.605" x2="79.71" y2="85.598"/>
            <g>
                <path style={styles} d="M72.839,73.293c-0.017,0.033-0.051,0.066-0.101,0.1
					c0-0.016,0-0.051,0-0.067C72.771,73.326,72.804,73.31,72.839,73.293z"/>
                <path style={styles} d="M72.755,73.715l-0.035,0.049
					c-0.017-0.101-0.017-0.201-0.033-0.303c0.017-0.033,0.033-0.049,0.051-0.068C72.755,73.494,72.755,73.613,72.755,73.715z"/>
                <path style={styles} d="M72.738,73.326c0,0.017,0,0.051,0,0.067
					c-0.018,0.019-0.035,0.035-0.051,0.068c0-0.033,0-0.084,0-0.119C72.704,73.326,72.72,73.326,72.738,73.326z"/>
                <path style={styles} d="M72.704,72.398c0.017,0.305,0.017,0.625,0.035,0.928
					c-0.018,0-0.035,0-0.051,0.017c-0.035-0.388-0.051-0.775-0.084-1.197C72.671,72.212,72.704,72.314,72.704,72.398z"/>
                <path style={styles} d="M72.72,73.765c0.035,0.508,0.051,0.996,0.051,1.452
					c-0.49-0.151-0.812-0.387-0.996-0.708l0.084-0.084L72.72,73.765z"/>
                <path style={styles} d="M72.738,76.162l-0.946,1.014l0.557-0.828v-0.016
					C72.468,76.28,72.603,76.231,72.738,76.162z"/>
                <path style={styles} d="M72.687,73.461c0.017,0.102,0.017,0.203,0.033,0.303
					l-0.861,0.66l0.051-0.271C72.181,73.935,72.45,73.699,72.687,73.461z"/>
                <path style={styles} d="M72.687,73.343c0,0.035,0,0.085,0,0.119
					c-0.237,0.237-0.506,0.473-0.776,0.692l0.168-0.76C72.282,73.394,72.501,73.377,72.687,73.343z"/>
                <path style={styles} d="M72.687,73.343c-0.186,0.035-0.405,0.051-0.608,0.051
					l0.303-1.469c0.085,0.051,0.169,0.119,0.221,0.203c0,0.018,0,0.018,0,0.018C72.636,72.568,72.652,72.955,72.687,73.343z"/>
                <path style={styles} d="M72.552,71.132c0,0.338,0.033,0.674,0.051,0.996
					c-0.051-0.084-0.135-0.152-0.221-0.203L72.552,71.132z"/>
                <path style={styles} d="M72.552,71.064v0.067l-0.169,0.793
					c-0.168-0.066-0.388-0.049-0.523,0.119c-0.051,0.068-0.1,0.152-0.151,0.219c-0.017-0.117-0.035-0.219-0.051-0.338
					c0.017,0,0-0.016,0-0.016c0.051-0.238,0.103-0.455,0.152-0.692C72.046,71.183,72.298,71.132,72.552,71.064z"/>
                <path style={styles} d="M71.911,72.195c-0.119,0.422-0.219,0.844-0.287,1.25
					c-0.018,0.017-0.018,0.017-0.018,0.017c-0.033,0-0.068,0-0.101,0c-0.017-0.472,0.051-0.996,0.151-1.537
					c0.017,0.119,0.035,0.221,0.051,0.338c0.051-0.067,0.101-0.151,0.151-0.219c0.135-0.168,0.354-0.185,0.523-0.119l-0.303,1.469
					c-0.135,0.019-0.271,0.035-0.388,0.051C71.792,73.04,71.859,72.617,71.911,72.195z"/>
                <path style={styles} d="M72.349,76.348l-0.557,0.828l-1.047,1.115
					c-0.051-0.322-0.084-0.625-0.101-0.945c-0.051,0.017-0.119,0.017-0.169,0.017c0.018-0.069,0.051-0.119,0.067-0.187
					C71.134,76.905,71.741,76.635,72.349,76.348z"/>
                <path style={styles} d="M72.079,73.394l-0.168,0.76
					c-0.067,0.084-0.135,0.151-0.219,0.203c-0.051-0.101-0.085-0.219-0.119-0.354c0-0.016-0.017-0.016,0-0.033
					c0.051-0.17,0.101-0.34,0.119-0.524C71.809,73.428,71.944,73.412,72.079,73.394z"/>
                <path style={styles} d="M71.691,74.356c0.084-0.051,0.152-0.119,0.219-0.203
					l-0.051,0.271l-0.084,0.084C71.741,74.457,71.725,74.408,71.691,74.356z"/>
                <path style={styles} d="M71.809,71.216c-0.049,0.237-0.101,0.455-0.152,0.692
					c-0.016-0.203-0.051-0.422-0.067-0.642v-0.018C71.657,71.249,71.725,71.232,71.809,71.216z"/>
                <path style={styles} d="M71.792,77.175l-0.979,1.469
					c-0.033-0.119-0.051-0.237-0.068-0.354L71.792,77.175z"/>
                <path style={styles} d="M71.911,72.195c-0.051,0.422-0.119,0.844-0.219,1.25
					c-0.018,0-0.051,0-0.067,0C71.691,73.04,71.792,72.617,71.911,72.195z"/>
                <path style={styles} d="M71.691,74.356c0.033,0.051,0.049,0.101,0.084,0.152
					l-0.371,0.287c0-0.051,0.016-0.117,0.016-0.186C71.505,74.543,71.589,74.457,71.691,74.356z"/>
                <path style={styles} d="M71.691,73.445c-0.018,0.185-0.067,0.354-0.119,0.524
					c-0.017-0.053-0.035-0.119-0.035-0.17v-0.018c0.018-0.101,0.051-0.201,0.068-0.32c0,0,0,0,0.018-0.017
					C71.641,73.445,71.673,73.445,71.691,73.445z"/>
                <path style={styles} d="M71.37,73.494c-0.101,0.019-0.203,0.053-0.303,0.103
					c0-0.776,0.049-1.537,0.151-2.262c0.119-0.033,0.236-0.051,0.371-0.068c0.017,0.219,0.051,0.438,0.067,0.642
					c0,0,0.017,0.016,0,0.016c-0.101,0.541-0.168,1.065-0.151,1.537C71.454,73.478,71.421,73.478,71.37,73.494z"/>
                <path style={styles} d="M71.573,74.002c0.033,0.135,0.068,0.254,0.119,0.354
					c-0.102,0.101-0.186,0.187-0.271,0.252c0-0.017,0-0.05,0.017-0.084c0,0,0-0.016,0-0.033
					C71.489,74.338,71.538,74.17,71.573,74.002z"/>
                <path style={styles} d="M71.606,73.461c-0.017,0.119-0.05,0.219-0.068,0.32
					c-0.017-0.101-0.033-0.201-0.033-0.32C71.538,73.461,71.573,73.461,71.606,73.461z"/>
                <path style={styles} d="M71.589,71.249v0.018c-0.135,0.017-0.252,0.035-0.371,0.068
					c0.033-0.237,0.068-0.473,0.135-0.709c0.119,0.049,0.203,0.185,0.219,0.371C71.573,71.081,71.589,71.167,71.589,71.249z"/>
                <path style={styles} d="M71.573,73.969c-0.017,0.017,0,0.017,0,0.033
					c-0.035,0.168-0.084,0.336-0.135,0.49c0.017-0.219,0.068-0.457,0.101-0.692C71.538,73.851,71.556,73.917,71.573,73.969z"/>
                <path style={styles} d="M71.538,73.781v0.018c-0.033,0.236-0.084,0.473-0.101,0.692
					c0,0.017,0,0.033,0,0.033c-0.017,0.035-0.017,0.068-0.033,0.101c-0.018,0.019-0.018,0.019-0.035,0.035c0-0.354,0-0.76,0-1.166
					c0.051-0.016,0.084-0.016,0.135-0.033C71.505,73.58,71.521,73.681,71.538,73.781z"/>
                <path style={styles} d="M71.438,74.524c-0.017,0.035-0.017,0.068-0.017,0.084
					c-0.016,0.016-0.016,0.016-0.016,0.016C71.421,74.592,71.421,74.559,71.438,74.524z"/>
                <path style={styles} d="M71.405,74.625c0,0,0,0,0.016-0.016
					c0,0.069-0.016,0.135-0.016,0.186L71.37,74.83c0-0.017,0-0.051,0-0.069C71.387,74.711,71.387,74.678,71.405,74.625z"/>
                <path style={styles} d="M71.405,74.795c-0.018,0.219-0.035,0.422-0.035,0.641
					c0-0.151,0-0.354,0-0.606L71.405,74.795z"/>
                <path style={styles} d="M71.405,74.625c-0.018,0.053-0.018,0.086-0.035,0.135
					c0-0.033,0-0.066,0-0.101C71.387,74.644,71.387,74.644,71.405,74.625z"/>
                <path style={styles} d="M71.37,74.76c0,0.018,0,0.053,0,0.069l-0.017,0.017
					C71.354,74.813,71.354,74.795,71.37,74.76z"/>
                <path style={styles} d="M71.37,74.66c0,0.035,0,0.068,0,0.101
					c-0.017,0.035-0.017,0.053-0.017,0.086l-1.317,1.029c0-0.371,0-0.758,0.017-1.131c0.051-0.135,0.119-0.252,0.187-0.371
					c0.168-0.303,0.371-0.506,0.59-0.641c0.035,0.371,0.103,0.742,0.254,1.198C71.167,74.846,71.27,74.76,71.37,74.66z"/>
                <path style={styles} d="M71.067,73.597c0.101-0.05,0.203-0.084,0.303-0.103
					c0,0.406,0,0.811,0,1.166c-0.101,0.101-0.203,0.187-0.287,0.271c-0.151-0.457-0.219-0.828-0.254-1.198
					C70.897,73.681,70.982,73.629,71.067,73.597z"/>
                <path style={styles} d="M70.036,75.875l1.317-1.029
					c-0.254,0.793-0.524,1.569-0.811,2.329c-0.101,0.051-0.185,0.084-0.271,0.117c-0.133-0.049-0.236-0.185-0.236-0.387
					C70.036,76.567,70.036,76.213,70.036,75.875z"/>
                <path style={styles} d="M71.134,69.139l-0.271,0.997
					c-0.016-0.035-0.051-0.067-0.102-0.067C70.897,69.766,71.016,69.46,71.134,69.139z"/>
                <path style={styles} d="M70.796,72.72c-0.017,0.185-0.168,0.235-0.236,0.387
					c-0.018,0,0,0.018,0,0.018c-0.018,0-0.018,0-0.035,0c0-0.457,0-1.065,0-1.571c0.203-0.101,0.438-0.17,0.692-0.219
					c-0.102,0.725-0.151,1.485-0.151,2.262c-0.084,0.033-0.17,0.084-0.238,0.135c-0.016-0.084-0.016-0.168-0.016-0.254h0.016
					c0-0.033-0.016-0.084-0.016-0.119c-0.017-0.066-0.017-0.134,0-0.218C70.796,73.006,70.796,72.871,70.796,72.72z"/>
                <path style={styles} d="M70.864,70.136l-0.338,1.283c0-0.051,0-0.101,0-0.152
					c-0.151,0.473-0.32,0.861-0.473,1.199c0-0.168,0-0.354,0-0.524c0.203-0.606,0.457-1.231,0.709-1.873
					C70.813,70.069,70.848,70.102,70.864,70.136z"/>
                <path style={styles} d="M70.813,73.359c0,0.034,0.016,0.085,0.016,0.119h-0.016
					v-0.017C70.813,73.428,70.796,73.394,70.813,73.359z"/>
                <path style={styles} d="M70.813,73.478c0,0.085,0,0.169,0.016,0.254
					c-0.219,0.135-0.422,0.338-0.59,0.641c-0.068,0.119-0.135,0.236-0.187,0.371c-0.017-0.319,0-0.623,0-0.944
					c0-0.457,0.49-0.557,0.76-0.338V73.478z"/>
                <path style={styles} d="M69.682,76.145c-0.033,0.49-0.051,0.979-0.051,1.453
					c-0.287,0.135-0.574,0.27-0.86,0.387l1.35-5.048c0.085,0.135,0.254,0.205,0.405,0.188c0,0.033,0,0.066,0,0.101
					c0-0.035,0.017-0.067,0.035-0.101c0,0-0.018-0.018,0-0.018c0.084,0,0.152-0.035,0.236-0.084c0,0.033,0,0.084,0.017,0.119
					c-0.017,0.084-0.017,0.152,0,0.218c-0.017,0.034,0,0.069,0,0.102c-0.271-0.219-0.76-0.119-0.76,0.338
					c0,0.321-0.017,0.625,0,0.944C69.868,75.166,69.75,75.657,69.682,76.145z"/>
                <path style={styles} d="M70.745,78.29c0.017,0.117,0.035,0.235,0.068,0.354
					l-3.663,5.47l1.621-6.129c0.287-0.117,0.574-0.252,0.86-0.387c0,0.674,0.051,1.317,0.051,1.839L70.745,78.29z"/>
                <path style={styles} d="M70.796,72.72c0,0.151,0,0.287,0.017,0.422
					c-0.017-0.035-0.017-0.086-0.017-0.119c-0.084,0.049-0.152,0.084-0.236,0.084C70.628,72.955,70.78,72.904,70.796,72.72z"/>
                <path style={styles} d="M70.745,78.29l-1.063,1.147
					c0.254-0.674,0.524-1.366,0.793-2.075c0.051,0,0.119,0,0.169-0.017C70.661,77.665,70.694,77.968,70.745,78.29z"/>
                <path style={styles} d="M70.053,70.423c0-0.406,0.44-0.541,0.709-0.354
					c-0.252,0.641-0.506,1.266-0.709,1.873C70.053,71.437,70.053,70.929,70.053,70.423z"/>
                <path style={styles} d="M70.561,73.125c-0.018,0.033-0.035,0.066-0.035,0.101
					c0-0.035,0-0.067,0-0.101C70.542,73.125,70.542,73.125,70.561,73.125z"/>
                <path style={styles} d="M70.542,77.175c-0.017,0.068-0.049,0.117-0.067,0.187
					c-0.068,0-0.135-0.017-0.203-0.069C70.358,77.259,70.442,77.227,70.542,77.175z"/>
                <path style={styles} d="M70.526,71.554c0,0.506,0,1.114,0,1.571
					c-0.151,0.017-0.32-0.053-0.405-0.188l0.405-1.518C70.526,71.47,70.526,71.503,70.526,71.554z"/>
                <path style={styles} d="M70.526,71.267c0,0.051,0,0.101,0,0.152l-0.405,1.518
					c-0.05-0.066-0.068-0.151-0.068-0.252c0-0.068,0-0.152,0-0.219C70.206,72.128,70.375,71.74,70.526,71.267z"/>
                <path style={styles} d="M70.272,77.292c0.068,0.053,0.135,0.069,0.203,0.069
					c-0.269,0.709-0.539,1.401-0.793,2.075c0-0.522-0.051-1.166-0.051-1.839C69.833,77.497,70.053,77.411,70.272,77.292z"/>
                <path style={styles} d="M70.272,77.292c-0.219,0.119-0.438,0.205-0.641,0.305
					c0-0.473,0.018-0.963,0.051-1.453l0.354-0.27c0,0.338,0,0.692,0,1.03C70.036,77.108,70.139,77.243,70.272,77.292z"/>
                <path style={styles} d="M70.053,71.941c0,0.17,0,0.356,0,0.524
					c-0.135,0.27-0.252,0.524-0.371,0.776C69.766,72.836,69.885,72.398,70.053,71.941z"/>
                <path style={styles} d="M70.053,74.744c-0.017,0.373-0.017,0.76-0.017,1.131
					l-0.354,0.27C69.75,75.657,69.868,75.166,70.053,74.744z"/>
                <path style={styles} d="M32.865,91.811c2.431,0,9.504-2.498,18.519-6.23
					c5.301-2.194,11.275-4.81,17.387-7.596c0.287-0.117,0.574-0.252,0.86-0.387c0.203-0.101,0.422-0.187,0.641-0.305"/>
                <line style={styles} x1="69.682" y1="76.145" x2="70.036" y2="75.875"/>
                <line style={styles} x1="69.631" y1="76.196" x2="69.682" y2="76.145"/>
                <line style={styles} x1="72.552" y1="71.132" x2="72.382" y2="71.925"/>
                <polyline style={styles}
                          points="70.121,72.937 68.771,77.985 67.15,84.114 				"/>
                <line style={styles} x1="71.134" y1="69.139" x2="70.864" y2="70.136"/>
                <path style={styles} d="M71.809,71.216c-0.084,0.016-0.152,0.033-0.219,0.033"/>
                <path style={styles} d="M72.552,71.064c-0.253,0.067-0.506,0.119-0.743,0.152"/>
                <line style={styles} x1="72.568" y1="71.048" x2="72.552" y2="71.064"/>
                <path style={styles} d="M72.771,70.997c-0.068,0.018-0.135,0.034-0.203,0.051"/>
                <path style={styles} d="M70.475,77.362c-0.269,0.709-0.539,1.401-0.793,2.075
					c0-0.522-0.051-1.166-0.051-1.839c0-0.473,0.018-0.963,0.051-1.453c0.067-0.488,0.186-0.979,0.371-1.401"/>
                <line style={styles} x1="72.552" y1="71.132" x2="72.687" y2="72.82"/>
                <path style={styles} d="M72.603,72.128"/>
                <line style={styles} x1="69.682" y1="79.437" x2="70.745" y2="78.29"/>
                <path style={styles} d="M70.561,73.107c0.084,0,0.152-0.035,0.236-0.084
					c0,0.033,0,0.084,0.017,0.119"/>
                <path style={styles} d="M70.526,73.125c0.017,0,0.017,0,0.035,0"/>
                <path style={styles} d="M70.526,73.242v-0.017c0-0.035,0-0.067,0-0.101
					c0-0.457,0-1.065,0-1.571c0-0.051,0-0.084,0-0.135s0-0.101,0-0.152c-0.151,0.473-0.32,0.861-0.473,1.199
					c-0.135,0.27-0.252,0.524-0.371,0.776c0.084-0.406,0.203-0.844,0.371-1.301c0.203-0.606,0.457-1.231,0.709-1.873
					c0.135-0.303,0.254-0.608,0.373-0.93l0.017-0.05c0.067-0.203,0.151-0.405,0.219-0.625"/>
                <path style={styles} d="M70.561,73.125c-0.018,0.033-0.035,0.066-0.035,0.101"/>
                <path style={styles} d="M70.796,72.685c0,0.018,0,0.035,0,0.035
					c-0.017,0.185-0.168,0.235-0.236,0.387"/>
                <path style={styles} d="M70.813,73.142c-0.017-0.135-0.017-0.271-0.017-0.422"/>
                <path style={styles} d="M70.948,79.15c0.203,0.271,0.692,0.305,0.844-0.035
					c0.102-0.201,0.168-0.371,0.254-0.574c0.017,0.051,0.033,0.086,0.084,0.119c0.101,0.103,0.236,0.135,0.371,0.152
					c0.035,0.186,0.035,0.373,0.067,0.574c0,0.016,0,0.035,0,0.051c0,0.035,0,0.084,0.017,0.119
					c0.086,0.556,0.963,0.404,0.963-0.119c0-0.574-0.017-1.131-0.033-1.688c0.59,0.506,1.113,1.014,1.586,1.688
					c0.422,0.592,1.401,0,0.963-0.574c-0.709-0.944-1.503-1.739-2.465-2.415c-0.051-0.033-0.102-0.049-0.151-0.066
					c-0.018-0.169-0.018-0.356-0.035-0.524c6.871-3.208,13.707-6.515,19.75-9.604c7.731-3.933,14.129-7.461,17.589-9.825
					L79.71,85.598l-21.456,15.008l0.253,38.455c-7.36-4.761-19.986-8.846-22.957-17.911c-0.102-0.32-2.406,4.394-2.76,4.427
					l0.075-33.765c2.431,0,9.504-2.498,18.519-6.23c5.301-2.194,11.275-4.81,17.387-7.596l-1.621,6.129l3.663-5.47
					c0.016,0.119,0.035,0.236,0.067,0.354C70.897,79.05,70.915,79.116,70.948,79.15z"/>
                <path style={styles} d="M85.128,68.483c0.236-0.119,0.471-0.221,0.709-0.321
					c1.198,0,2.362-0.101,3.393-0.625c-1.166-0.085-2.296,0.203-3.393,0.625c-0.271,0.016-0.557,0.016-0.828,0.016
					C85.01,68.278,85.061,68.381,85.128,68.483z M78.477,71.655H78.46c0.068-0.101,0.152-0.201,0.22-0.304
					c-0.354,0.354-0.574,0.726-0.877,1.217c-0.034,0.049-0.067,0.101-0.102,0.152c0,0,0,0.016-0.017,0.033
					c-0.27,0.422-0.506,0.86-0.692,1.333c0.254-0.539,0.541-1.046,0.844-1.518c0.017-0.035,0.033-0.086,0.067-0.119
					C78.106,72.179,78.291,71.925,78.477,71.655z M78.612,71.486c0.152-0.203,0.303-0.389,0.49-0.557
					c-0.354,0.709-0.692,1.401-1.063,2.11c0.303-0.438,0.692-0.877,1.047-1.316c-0.186,0.304-0.373,0.64-0.608,0.877
					c0.406-0.406,1.03-1.031,1.453-1.453c-0.203,0.625-0.608,1.25-0.828,1.672c0.455-0.457,1.047-1.047,1.587-1.654
					c-0.051,0.066-0.085,0.117-0.119,0.185c0.168-0.168,0.32-0.422,0.473-0.641c0.049-0.051,0.084-0.101,0.117-0.151
					c-0.033,0-0.068-0.019-0.101-0.051c-0.017-0.017,0-0.033,0.016-0.033c0.135-0.221,0.271-0.438,0.422-0.625h0.016
					c1.014-0.289,2.01-0.575,2.87-1.25c-0.844,0.422-1.688,0.625-2.329,1.063c0.422-0.438,0.641-1.063,1.047-1.485
					c-0.422,0.338-0.811,0.709-1.166,1.131c0.692-0.809,1.52-1.553,2.364-2.126c-1.991,1.063-3.73,2.126-5.167,3.698
					c0.051-0.102,0.103-0.221,0.153-0.321C79.034,70.896,78.814,71.2,78.612,71.486z M79.018,69.427l0.016-0.018l2.262-2.43
					l0.035-0.033h0.017l11.816-0.692c-6.043,3.089-12.88,6.396-19.75,9.604c-0.017-0.135-0.033-0.271-0.033-0.389L79.018,69.427z"/>
                <path style={styles} d="M85.837,68.162c1.096-0.422,2.227-0.71,3.393-0.625
					C88.2,68.061,87.036,68.162,85.837,68.162z"/>
                <path style={styles} d="M85.01,68.178c0.271,0,0.557,0,0.828-0.016
					c-0.238,0.101-0.473,0.203-0.709,0.321C85.061,68.381,85.01,68.278,85.01,68.178z"/>
                <path style={styles} d="M82.056,69.663c0.641-0.438,1.485-0.641,2.329-1.063
					c-0.86,0.674-1.856,0.961-2.87,1.25c0-0.035,0.019-0.051,0.035-0.068c0.017-0.033,0.051-0.067,0.084-0.102
					c0.033-0.066,0.067-0.119,0.135-0.168c0.051-0.068,0.101-0.135,0.168-0.203c0.354-0.422,0.743-0.792,1.166-1.131
					C82.697,68.6,82.478,69.225,82.056,69.663z"/>
                <path style={styles} d="M79.929,71.148c-0.422,0.422-1.047,1.047-1.453,1.453
					c0.236-0.238,0.422-0.574,0.608-0.877c-0.354,0.439-0.744,0.877-1.047,1.316c0.371-0.709,0.709-1.401,1.063-2.11
					c0.017-0.017,0.017-0.033,0.033-0.049c1.436-1.571,3.175-2.634,5.167-3.698c-0.844,0.574-1.671,1.317-2.364,2.126
					c-0.068,0.068-0.117,0.135-0.168,0.203c-0.067,0.049-0.102,0.102-0.135,0.168c-0.033,0.035-0.068,0.069-0.084,0.102
					c-0.035,0.017-0.051,0.033-0.051,0.068h-0.017c-0.237,0.084-0.457,0.151-0.692,0.219c0.084,0.219,0.152,0.354,0.236,0.422
					c0,0,0.018,0,0.018,0.017c-0.119,0.203-0.254,0.405-0.338,0.625c-0.017,0.017-0.017,0.017-0.017,0.017v0.018
					c-0.541,0.607-1.132,1.197-1.587,1.654C79.321,72.398,79.726,71.773,79.929,71.148z M79.169,71.605
					c0.068-0.085,0.135-0.168,0.187-0.254C79.27,71.437,79.22,71.52,79.169,71.605z"/>
                <path style={styles} d="M81.549,69.782c-0.016,0.017-0.035,0.033-0.035,0.068
					h-0.016C81.499,69.815,81.515,69.798,81.549,69.782z"/>
                <path style={styles} d="M81.482,69.85h0.017c-0.152,0.186-0.287,0.404-0.422,0.625
					c-0.016,0.017-0.033,0.017-0.051,0.017C81.161,70.271,81.312,70.052,81.482,69.85z"/>
                <path style={styles} d="M81.482,69.85c-0.17,0.203-0.321,0.422-0.457,0.641
					c-0.084-0.067-0.152-0.203-0.236-0.422C81.025,70.001,81.245,69.934,81.482,69.85z"/>
                <path style={styles} d="M81.295,66.979l-2.262,2.43
					c0.035-0.252,0.101-0.504,0.135-0.758h0.017L81.295,66.979z"/>
                <path style={styles} d="M81.161,70.559c-0.033,0.051-0.068,0.1-0.117,0.151
					c-0.102,0.152-0.221,0.287-0.338,0.422c0.084-0.219,0.219-0.422,0.338-0.625h0.017C81.093,70.54,81.127,70.559,81.161,70.559z"
                />
                <path style={styles} d="M81.076,70.474c-0.016,0-0.033,0.017-0.016,0.033h-0.017
					c0-0.017-0.018-0.017-0.018-0.017C81.043,70.491,81.06,70.491,81.076,70.474z"/>
                <path style={styles} d="M80.706,71.132c0.117-0.135,0.236-0.27,0.338-0.422
					c-0.153,0.219-0.305,0.473-0.473,0.641c0.033-0.067,0.067-0.119,0.119-0.185v-0.018C80.689,71.148,80.689,71.148,80.706,71.132z
					"/>
                <path style={styles} d="M79.355,71.351c-0.051,0.086-0.119,0.168-0.187,0.254
					C79.22,71.52,79.27,71.437,79.355,71.351z"/>
                <path style={styles} d="M79.288,70.559c-0.051,0.1-0.102,0.219-0.153,0.321
					c-0.016,0.017-0.016,0.033-0.033,0.049c-0.187,0.168-0.338,0.354-0.49,0.557C78.814,71.2,79.034,70.896,79.288,70.559z"/>
                <path style={styles} d="M75.625,71.454l3.544-2.803
					c-0.035,0.254-0.101,0.506-0.135,0.758l-0.016,0.018l-5.638,6.042c-0.035-0.185-0.051-0.369-0.085-0.556l2.296-3.427
					l0.016-0.016L75.625,71.454z"/>
                <path style={styles} d="M78.68,71.351c-0.068,0.103-0.152,0.203-0.22,0.304
					c-0.135,0.204-0.287,0.389-0.438,0.608c-0.085,0.101-0.153,0.203-0.219,0.305C78.106,72.077,78.325,71.706,78.68,71.351z"/>
                <path style={styles} d="M78.46,71.655h0.017c-0.186,0.27-0.371,0.524-0.574,0.794
					c0.033-0.067,0.084-0.119,0.119-0.187C78.173,72.043,78.325,71.859,78.46,71.655z"/>
                <path style={styles} d="M78.021,72.263c-0.034,0.068-0.085,0.119-0.119,0.187
					c-0.035,0.033-0.051,0.084-0.067,0.119c-0.051,0.049-0.084,0.101-0.135,0.152c0.035-0.051,0.068-0.103,0.102-0.152
					C77.868,72.466,77.936,72.363,78.021,72.263z"/>
                <path style={styles} d="M77.7,72.72c0.051-0.051,0.084-0.103,0.135-0.152
					c-0.303,0.472-0.59,0.979-0.844,1.518c0.187-0.473,0.422-0.912,0.692-1.333C77.7,72.736,77.7,72.72,77.7,72.72z"/>
                <path style={styles} d="M73.599,76.448c0.961,0.676,1.756,1.471,2.465,2.415
					c0.438,0.574-0.541,1.166-0.963,0.574c-0.473-0.674-0.996-1.182-1.586-1.688c0.017,0.557,0.033,1.114,0.033,1.688
					c0,0.523-0.877,0.674-0.963,0.119c-0.017-0.035-0.017-0.084-0.017-0.119c0-0.016,0-0.035,0-0.051
					c-0.033-0.201-0.033-0.388-0.067-0.574c-0.135-0.017-0.271-0.049-0.371-0.152c-0.051-0.033-0.067-0.067-0.084-0.119
					c-0.086,0.203-0.152,0.373-0.254,0.574c-0.151,0.339-0.641,0.305-0.844,0.035c-0.033-0.035-0.051-0.101-0.068-0.152
					c-0.033-0.119-0.051-0.235-0.067-0.354l0.979-1.469l0.946-1.014c0.219-0.101,0.438-0.201,0.674-0.303"/>
                <path style={styles} d="M75.606,71.47l-0.016,0.016l-1.047,0.828
					c0.017-0.151,0.035-0.287,0.035-0.438c0.049-0.017,0.117-0.035,0.168-0.051v0.017c0.017-0.017,0.051-0.035,0.068-0.051
					c0.016,0,0.016-0.016,0.034-0.016C75.101,71.671,75.354,71.57,75.606,71.47z"/>
                <path style={styles} d="M75.336,71.384l0.27,0.086
					c-0.252,0.1-0.506,0.201-0.758,0.303C75.033,71.638,75.185,71.52,75.336,71.384z"/>
                <path style={styles} d="M73.497,73.142l0.776-0.608
					c0.051,0.152,0.119,0.287,0.187,0.422c0.051-0.203,0.084-0.422,0.084-0.641l1.047-0.828l-2.296,3.427
					c-0.017-0.135-0.033-0.254-0.05-0.389c-0.034-0.186-0.135-0.269-0.27-0.303c0-0.051,0-0.084,0-0.135
					c0-0.169-0.084-0.287-0.203-0.371L73.497,73.142z"/>
                <path style={styles} d="M75.336,71.384c-0.152,0.135-0.303,0.254-0.488,0.389
					c-0.018,0-0.018,0.016-0.034,0.016c-0.017,0-0.051,0.017-0.068,0.035c-0.051-0.219-0.101-0.44-0.151-0.676L75.336,71.384z"/>
                <path style={styles} d="M74.814,71.79c-0.017,0.017-0.051,0.035-0.068,0.051v-0.017
					C74.763,71.806,74.797,71.79,74.814,71.79z"/>
                <path style={styles} d="M74.746,71.824c-0.051,0.017-0.119,0.035-0.168,0.051
					c0-0.237-0.018-0.491-0.035-0.744l0.051,0.017C74.646,71.384,74.695,71.605,74.746,71.824z"/>
                <path style={styles} d="M74.543,71.132c0.017,0.252,0.035,0.506,0.035,0.744
					c-0.152,0.066-0.322,0.117-0.473,0.168c-0.017-0.067-0.035-0.135-0.051-0.203h0.119c-0.051-0.051-0.119-0.101-0.151-0.17
					c-0.051-0.252-0.084-0.488-0.119-0.742L74.543,71.132z"/>
                <path style={styles} d="M74.578,71.875c0,0.152-0.018,0.287-0.035,0.438
					l-0.271,0.219c-0.067-0.151-0.117-0.321-0.168-0.49C74.256,71.993,74.426,71.941,74.578,71.875z"/>
                <path style={styles} d="M74.543,72.314c0,0.219-0.033,0.438-0.084,0.641
					c-0.068-0.135-0.135-0.27-0.187-0.422L74.543,72.314z"/>
                <path style={styles} d="M74.105,72.043c0.051,0.168,0.101,0.338,0.168,0.49
					l-0.776,0.608c0-0.069-0.017-0.135-0.049-0.221c0.201-0.217,0.336-0.557,0.404-1.08h0.203
					C74.07,71.909,74.088,71.976,74.105,72.043z"/>
                <path style={styles} d="M74.021,71.671c0.033,0.069,0.101,0.119,0.151,0.17h-0.119
					C74.037,71.773,74.037,71.724,74.021,71.671z"/>
                <path style={styles} d="M74.054,71.841h-0.203c0.018-0.067,0.035-0.135,0.035-0.203
					c0.017-0.017,0.017-0.049,0.017-0.084c0.034,0.051,0.067,0.084,0.119,0.117C74.037,71.724,74.037,71.773,74.054,71.841z"/>
                <path style={styles} d="M74.021,71.671c-0.051-0.033-0.084-0.066-0.119-0.117
					c-0.187-0.236-0.354-0.523-0.49-0.861l0.254-0.119l-0.051,0.271l0.287,0.084C73.937,71.183,73.97,71.419,74.021,71.671z"/>
                <path style={styles} d="M73.886,71.638c0,0.067-0.017,0.135-0.035,0.203h-0.793
					C73.058,71.841,73.734,71.841,73.886,71.638z"/>
                <path style={styles} d="M73.497,73.142l-0.725,0.574h-0.017
					c0-0.103,0-0.221-0.017-0.322c0.049-0.034,0.084-0.067,0.101-0.1c0.236-0.051,0.438-0.168,0.608-0.373
					C73.48,73.006,73.497,73.072,73.497,73.142z"/>
                <path style={styles} d="M73.329,72.685c0.051,0.084,0.084,0.169,0.119,0.235
					c-0.17,0.205-0.373,0.322-0.608,0.373C73.075,73.056,73.244,72.854,73.329,72.685z"/>
                <path style={styles} d="M73.379,75.469c0,0.119,0.017,0.254,0.033,0.389
					c-0.236,0.102-0.455,0.203-0.674,0.303L73.379,75.469z"/>
                <path style={styles} d="M73.294,74.914c0.035,0.187,0.051,0.371,0.085,0.556
					l-0.641,0.692c-0.135,0.069-0.271,0.119-0.389,0.17L73.294,74.914z"/>
                <path style={styles} d="M71.37,75.436c0-0.219,0.017-0.422,0.035-0.641l0.371-0.287
					c0.185,0.321,0.506,0.557,0.996,0.708c0-0.457-0.017-0.944-0.051-1.452l0.035-0.049h0.017c0.119,0.084,0.203,0.201,0.203,0.371
					c0,0.051,0,0.084,0,0.135c0.135,0.035,0.236,0.117,0.27,0.303c0.017,0.135,0.033,0.254,0.05,0.389l-0.945,1.418v0.016
					c-0.608,0.287-1.215,0.558-1.807,0.828c0.287-0.76,0.557-1.536,0.811-2.329l0.017-0.017C71.37,75.082,71.37,75.285,71.37,75.436
					z"/>
                <polyline style={styles} points="113.521,53.83 110.752,56.429 79.71,85.598
					"/>
                <path style={styles} d="M73.413,75.858c6.871-3.208,13.707-6.515,19.75-9.604
					c7.731-3.933,14.129-7.461,17.589-9.825c1.772-1.199,2.768-2.11,2.768-2.6"/>
                <line style={styles} x1="79.186" y1="68.651" x2="81.295" y2="66.979"/>
                <line style={styles} x1="75.625" y1="71.454" x2="79.169" y2="68.651"/>
                <polyline style={styles} points="72.771,73.715 73.497,73.142 74.273,72.533
					74.543,72.314 75.59,71.486 				"/>
                <polyline style={styles} points="73.615,70.845 73.902,70.929 74.543,71.132
					74.595,71.148 75.336,71.384 75.606,71.47 				"/>
                <path style={styles} d="M73.902,71.554c0,0.035,0,0.067-0.017,0.084"/>
                <path style={styles} d="M73.851,71.841c0.018-0.067,0.035-0.135,0.035-0.203"/>
                <path style={styles} d="M72.738,73.326c0.033,0,0.066-0.017,0.101-0.033
					c0.236-0.051,0.438-0.168,0.608-0.373c0.201-0.217,0.336-0.557,0.404-1.08"/>
                <line style={styles} x1="81.295" y1="66.979" x2="81.33" y2="66.946"/>
                <line style={styles} x1="79.034" y1="69.409" x2="81.295" y2="66.979"/>
                <line style={styles} x1="73.379" y1="75.469" x2="79.018" y2="69.427"/>
                <line style={styles} x1="73.413" y1="70.693" x2="72.771" y2="70.997"/>
                <line style={styles} x1="73.667" y1="70.575" x2="73.413" y2="70.693"/>
                <polyline style={styles} points="81.33,66.946 74.408,70.22 74.375,70.237
					73.869,70.474 73.667,70.575 				"/>
                <line style={styles} x1="75.59" y1="71.486" x2="73.294" y2="74.914"/>
                <line style={styles} x1="75.606" y1="71.47" x2="75.59" y2="71.486"/>
                <path style={styles} d="M78.966,69.309c0,0-0.017,0-0.017,0.019
					c-0.119,0.049-0.592,0.287-0.828,0.539"/>
                <path style={styles} d="M75.625,71.454c1.18-0.508,2.378-1.2,3.325-2.126"/>
                <line style={styles} x1="75.606" y1="71.47" x2="75.625" y2="71.454"/>
                <path style={styles} d="M74.848,71.773c0.252-0.103,0.506-0.203,0.758-0.303"/>
                <path style={styles} d="M73.902,72.111c0.067-0.018,0.135-0.051,0.203-0.068
					c0.151-0.051,0.321-0.102,0.473-0.168c0.049-0.017,0.117-0.035,0.168-0.051c0.017-0.018,0.051-0.035,0.068-0.035"/>
                <path style={styles} d="M73.058,73.799c0-0.219,0.574-0.084,0.438-0.658
					c0-0.069-0.017-0.135-0.049-0.221c-0.035-0.066-0.068-0.151-0.119-0.235c-0.084,0.169-0.254,0.371-0.49,0.608
					c-0.017,0.033-0.051,0.066-0.101,0.1c-0.018,0.019-0.035,0.035-0.051,0.068c-0.237,0.237-0.506,0.473-0.776,0.692
					c-0.067,0.084-0.135,0.151-0.219,0.203c-0.102,0.101-0.186,0.187-0.271,0.252c-0.016,0.016-0.016,0.016-0.016,0.016
					c-0.018,0.019-0.018,0.019-0.035,0.035c-0.101,0.101-0.203,0.187-0.287,0.271c-0.151-0.457-0.219-0.828-0.254-1.198
					c-0.016-0.084-0.016-0.168-0.016-0.254v-0.017c0-0.033-0.017-0.068,0-0.102"/>
                <path style={styles} d="M74.51,70.794c0.017,0.119,0.05,0.237,0.084,0.354
					c0.051,0.236,0.1,0.457,0.151,0.676v0.017c0.017-0.017,0.051-0.035,0.068-0.051c0.016,0,1.016-1.016,1.034-1.016
					c0.185-0.135-0.664-0.254-0.512-0.389c1.333-1.147,2.196-1.717,3.9-2.207c0,0.152-0.017,0.322-0.051,0.473h-0.017
					c-0.035,0.254-0.101,0.506-0.135,0.758l-0.016,0.018c-0.035,0.152-0.051,0.287-0.051,0.439"/>
                <path style={styles} d="M74.375,70.237c0.033,0.168,0.084,0.338,0.117,0.508"/>
                <polyline style={styles} points="81.33,66.946 81.347,66.946 93.163,66.254
					96.523,66.068 				"/>
                <path style={styles} d="M85.837,68.162c-0.271,0.016-0.557,0.016-0.828,0.016
					c0,0.101,0.051,0.203,0.119,0.305c0.084,0.101,0.203,0.218,0.303,0.336"/>
                <path style={styles} d="M85.837,68.162c1.096-0.422,2.227-0.71,3.393-0.625
					C88.2,68.061,87.036,68.162,85.837,68.162z"/>
                <path style={styles} d="M81.009,70.507l0.017-0.017c0.018,0,0.035,0,0.051-0.017
					c1.301-0.541,2.651-1.368,4.052-1.991c0.236-0.119,0.471-0.221,0.709-0.321"/>
                <path style={styles} d="M81.482,69.85c-0.17,0.203-0.321,0.422-0.457,0.641"/>
                <path style={styles} d="M81.549,69.782c-0.035,0.017-0.051,0.033-0.051,0.068"/>
                <path style={styles} d="M84.385,67.131c-0.033,0.016-0.051,0.033-0.084,0.051
					c-0.844,0.574-1.671,1.317-2.364,2.126"/>
                <path style={styles} d="M79.134,70.88c1.436-1.571,3.175-2.634,5.167-3.698"/>
                <path style={styles} d="M78.612,71.486c0.152-0.203,0.303-0.389,0.49-0.557"/>
                <line style={styles} x1="78.46" y1="71.655" x2="78.477" y2="71.655"/>
                <path style={styles} d="M77.903,72.449c0.033-0.067,0.084-0.119,0.119-0.187"/>
                <path style={styles} d="M78.021,72.263c-0.085,0.101-0.153,0.203-0.219,0.305"/>
                <path style={styles} d="M78.021,72.263c0.152-0.219,0.304-0.404,0.438-0.608"/>
                <path style={styles} d="M77.802,72.568c0.304-0.491,0.523-0.862,0.877-1.217
					c-0.068,0.103-0.152,0.203-0.22,0.304"/>
                <path style={styles} d="M77.802,72.568c-0.034,0.049-0.067,0.101-0.102,0.152"/>
                <path style={styles} d="M77.835,72.568c-0.051,0.049-0.084,0.101-0.135,0.152
					c0,0,0,0.016-0.017,0.033c-0.27,0.422-0.506,0.86-0.692,1.333C77.245,73.547,77.532,73.04,77.835,72.568"/>
                <path style={styles} d="M77.835,72.568c0.017-0.035,0.033-0.086,0.067-0.119"/>
                <path style={styles} d="M78.477,71.655c-0.186,0.27-0.371,0.524-0.574,0.794"/>
                <path style={styles} d="M78.477,71.655c0.017-0.033,0.051-0.066,0.084-0.117
					c0.019-0.018,0.035-0.035,0.051-0.051"/>
                <path style={styles} d="M79.321,70.507c-0.017,0.017-0.017,0.033-0.033,0.051
					c-0.254,0.338-0.473,0.641-0.676,0.928"/>
                <path style={styles} d="M79.085,71.724c-0.354,0.439-0.744,0.877-1.047,1.316
					c0.371-0.709,0.709-1.401,1.063-2.11c0.017-0.017,0.017-0.033,0.033-0.049c0.051-0.102,0.103-0.221,0.153-0.321"/>
                <path style={styles} d="M79.523,71.132c-0.051,0.068-0.101,0.152-0.168,0.219
					c-0.051,0.086-0.119,0.168-0.187,0.254"/>
                <path style={styles} d="M79.169,71.605c0.051-0.085,0.101-0.168,0.187-0.254"/>
                <path style={styles} d="M79.169,71.605c-0.017,0.033-0.051,0.084-0.084,0.119"/>
                <path style={styles} d="M79.945,71.132l-0.016,0.017
					c-0.422,0.422-1.047,1.047-1.453,1.453c0.236-0.238,0.422-0.574,0.608-0.877"/>
                <path style={styles} d="M80.689,71.167c-0.541,0.607-1.132,1.197-1.587,1.654
					c0.219-0.422,0.625-1.047,0.828-1.672"/>
                <path style={styles} d="M81.043,70.71c-0.102,0.152-0.221,0.287-0.338,0.422"/>
                <path style={styles} d="M81.211,70.507c-0.016,0.017-0.033,0.033-0.051,0.051"/>
                <path style={styles} d="M81.161,70.559c-0.033,0.051-0.068,0.1-0.117,0.151"/>
                <path style={styles} d="M81.043,70.507c-0.119,0.203-0.254,0.405-0.338,0.625
					c-0.017,0.017-0.017,0.017-0.017,0.017v0.018c-0.051,0.066-0.085,0.117-0.119,0.185c0.168-0.168,0.32-0.422,0.473-0.641"/>
                <path style={styles} d="M81.499,69.85c-0.152,0.186-0.287,0.404-0.422,0.625
					c-0.016,0-0.033,0.017-0.016,0.033"/>
                <path style={styles} d="M81.515,69.85c1.014-0.289,2.01-0.575,2.87-1.25
					c-0.844,0.422-1.688,0.625-2.329,1.063c0.422-0.438,0.641-1.063,1.047-1.485c-0.422,0.338-0.811,0.709-1.166,1.131
					c-0.068,0.068-0.117,0.135-0.168,0.203c-0.067,0.049-0.102,0.102-0.135,0.168c-0.033,0.035-0.068,0.069-0.084,0.102
					C81.533,69.798,81.515,69.815,81.515,69.85"/>
                <line style={styles} x1="81.515" y1="69.85" x2="81.499" y2="69.85"/>
                <line style={styles} x1="81.499" y1="69.85" x2="81.482" y2="69.85"/>
                <path style={styles} d="M81.634,70.71c-0.203-0.101-0.354-0.101-0.473-0.151
					c-0.033,0-0.068-0.019-0.101-0.051h-0.017c0-0.017-0.018-0.017-0.018-0.017c-0.084-0.067-0.152-0.203-0.236-0.422
					c0.236-0.068,0.455-0.135,0.692-0.219"/>
            </g>
        </g>
    </g>,
    <g id="Layer_6">
    <g>
    <path style={styles} d="M63.137,165.789c1.924,3.327,0.169,6.551-3.19,7.563
    c-2.718,0.828-6.195,0.017-8.88-0.557c-0.354-0.083-0.573,0.271-0.489,0.541l-0.202,0.086l-0.372-0.811l-19.801-22.519v-0.033
    c0.287,0.25,0.844,0.115,0.86-0.373c0.186-8.796,2.127-17.59,3.342-26.335c0.422,0.862,0.945,1.925,1.25,2.348
    c1.452,2.042,3.478,3.813,5.503,5.283c4.305,3.172,9.031,5.638,13.589,8.424c-0.406,0.541-0.169,1.484,0.708,1.52
    c0.49,0.017,0.979,0.017,1.469,0.033c-0.017,0.103-0.017,0.218,0.034,0.353c1.113,3.327,2.009,6.703,2.886,10.112
    c0.946,3.716,0.237,7.58,1.569,11.226C61.82,163.748,62.546,164.777,63.137,165.789z"/>
    <path style={styles} d="M58.072,139.12c0.541,0.353,0.591,1.098,0.237,1.52
    c-0.405-0.491-1.3-0.287-1.384,0.32c-0.489-0.016-0.979-0.016-1.469-0.033c-0.877-0.036-1.114-0.979-0.708-1.52
    c-4.558-2.786-9.285-5.252-13.589-8.424c-2.025-1.47-4.051-3.241-5.503-5.283c-0.304-0.422-0.828-1.485-1.25-2.348
    c-1.215,8.745-3.156,17.539-3.342,26.335c-0.017,0.488-0.574,0.623-0.86,0.373c-0.084-0.086-0.152-0.221-0.152-0.373
    c0.118-9.522,3.207-18.773,3.156-28.275c-0.016-0.305,0.22-0.658,0.523-0.708c0.219-0.035,0.422-0.051,0.625-0.068
    c0.354-0.033,0.659,0.254,0.76,0.574C38.086,130.273,50.713,134.358,58.072,139.12z"/>
    <line style={styles} x1="30.203" y1="150.092" x2="50.004" y2="172.61"/>
    </g>
</g>,
<g id="Layer_16">
    <path style={styles} d="M213.561,258.179l9.774,38.031l-23.043-6.667l-7.781-5.892
			l-1.385-2.077c0.152,0.135,0.438,0.103,0.522-0.135c1.891-5.622,7.412-7.597,11.8-10.887
			C207.855,267.259,209.086,261.403,213.561,258.179z"/>
    <path style={styles} d="M213.054,257.249c0.557-0.353,1.047,0.541,0.506,0.93
    l-0.203-0.761L213.054,257.249z"/>
    <path style={styles} d="M213.357,257.417l0.203,0.761
    c-4.474,3.225-5.706,9.08-10.112,12.374c-4.389,3.29-9.909,5.265-11.8,10.887c-0.084,0.237-0.371,0.27-0.522,0.135
    c-0.084-0.066-0.119-0.168-0.084-0.303c1.67-5.265,6.093-7.276,10.331-10.247c5.181-3.63,6.464-10.296,11.646-13.758
    c0.017,0,0.017-0.017,0.035-0.017L213.357,257.417z"/>
    <line style={styles} x1="213.561" y1="258.179" x2="223.334" y2="296.21"/>
    <line style={styles} x1="200.292" y1="289.543" x2="223.334" y2="296.21"/>
    <path style={styles} d="M200.292,289.543"/>
    </g>,
    <g id="Layer_15">
        <path style={styles} d="M204.968,252.624l8.086,4.625
			c-0.018,0-0.018,0.017-0.035,0.017c-5.183,3.462-6.465,10.128-11.646,13.758c-4.238,2.971-8.661,4.982-10.331,10.247
			l-21.406-11.681l-0.252-0.017c0.574-0.626,2.11-2.314,2.362-2.314c0.389,0.069,0.727-0.083,0.862-0.488
			c0.674-2.024,0.81-3.749-0.051-5.707c-1.03-2.38-2.989-3.577-3.612-6.077c5.013,1.975,10.28,0.59,15.326-1.012
			c6.027-1.909,14.519-6.109,19.65-0.389C204.528,254.262,205.507,253.299,204.968,252.624z"/>
        <path style={styles} d="M204.968,252.624c0.539,0.676-0.44,1.639-1.046,0.963
			c-5.132-5.721-13.624-1.52-19.65,0.389c-5.046,1.603-10.313,2.987-15.326,1.012c0.623,2.5,2.582,3.696,3.612,6.077
			c0.861,1.958,0.726,3.683,0.051,5.707c-0.135,0.405-0.473,0.557-0.862,0.488c-0.252,0-1.789,1.688-2.362,2.314
			c-0.084,0.083-0.151,0.152-0.186,0.185c-0.287,0.271-0.709-0.151-0.438-0.438c2.6-2.75,4.372-5.724,1.518-9.182
			c-1.602-1.942-2.649-3.646-2.784-6.211c-0.035-0.508,0.557-0.778,0.963-0.557c9.047,4.86,20.863-5.387,30.164-4.171
			c2.28,0.304,4.406,1.283,6.313,3.376C204.95,252.59,204.968,252.607,204.968,252.624z"/>
        <polyline style={styles} points="204.968,252.624 213.054,257.249 213.357,257.417
			"/>
        <line style={styles} x1="169.423" y1="268.582" x2="191.042" y2="281.271"/>
    </g>,
    <g id="Layer_14">
    <path style={styles} d="M198.638,249.096l-0.018,0.102
    c-9.301-1.216-21.117,9.031-30.164,4.171c-0.406-0.221-0.998,0.049-0.963,0.557c0.135,2.565,1.182,4.27,2.784,6.211
    c2.854,3.458,4.458,8.121,1.858,10.87l-19.126-5.723c0.625-2.381,1.063-4.843,0.76-7.155l2.498-19.281L198.638,249.096z"/>
    <line style={styles} x1="153.77" y1="258.129" x2="156.267" y2="238.849"/>
    <polyline style={styles} points="156.267,238.849 198.638,249.096 198.655,249.096
    "/>
    <line style={styles} x1="153.009" y1="265.284" x2="169.423" y2="268.582"/>
    </g>,
    <g id="Layer_13">
        <path style={styles} d="M135.842,243.965c0.084-0.085,0.187-0.119,0.32-0.102
			c6.146,0.877,11.935,4.761,15.701,9.588c1.131,1.454,1.704,3.023,1.907,4.678l-0.912,7.122l-1.587-0.271
			c1.282-4.827,1.282-8.71-2.414-12.406c-3.562-3.561-7.934-6.736-12.896-8.002C135.623,244.49,135.639,244.134,135.842,243.965z"/>
        <path style={styles} d="M148.857,252.574c3.696,3.696,3.696,7.58,2.414,12.406
			l-85.72-14.619c-0.017-0.099-0.05-0.201-0.118-0.287l68.602-5.941l1.807-0.168c-0.203,0.168-0.219,0.524,0.119,0.607
			C140.923,245.838,145.294,249.013,148.857,252.574z"/>
        <path style={styles} d="M151.271,264.98"/>
        <polyline style={styles} points="65.433,250.075 134.035,244.134 135.842,243.965
			"/>
        <path style={styles} d="M151.254,265.083c0.017-0.033,3.393-0.069,3.393-0.103
			l-1.789,0.271"/>
        <path style={styles} d="M153.77,258.129c0.303,2.312-0.135,4.774-0.76,7.155
			l-0.151-0.033L153.77,258.129z"/>
    </g>,
    <g id="Layer_12">
    <path style={styles} d="M81.183,236.722c0.776-0.201,1.096,0.962,0.32,1.2
    c-0.101,0.033-0.203,0.082-0.303,0.119c-0.135,0.099-0.287,0.168-0.473,0.151c-6.447,2.143-11.698,6.06-15.647,11.579
    c-0.288-0.151-0.49-0.336-0.642-0.541c3.106-5.536,8.964-9.891,15.007-11.952c-0.354-0.369-0.676-0.792-1.012-1.147
    c-0.946-0.979-1.942-1.906-2.938-2.835c-1.401-1.268-2.835-2.5-4.355-3.612c0.168-0.254,0.338-0.508,0.506-0.745
    c1.688,0.996,3.257,2.229,4.761,3.447c1.367,1.111,3.714,2.647,4.659,4.372C81.115,236.739,81.148,236.739,81.183,236.722z"/>
    <path style={styles} d="M78.431,236.132c0.336,0.356,0.658,0.778,1.012,1.147
    c-6.043,2.061-11.9,6.416-15.007,11.952c-0.084-0.099-0.152-0.201-0.219-0.303c0.034-0.103,0.034-0.218-0.068-0.32
    c-0.067-0.053-0.101-0.135-0.135-0.205c-0.168-0.692-0.034-1.484,0.27-2.278c0.118-0.32,0.034-0.574-0.151-0.742
    c0.658-2.414,2.025-5.334,2.363-6.195c1.266-3.241,2.719-6.567,4.642-9.503c1.52,1.111,2.955,2.344,4.355,3.612
    C76.489,234.226,77.485,235.153,78.431,236.132z"/>
    <path style={styles} d="M71.931,228.554c-0.101,0.115-0.185,0.25-0.287,0.386
    c-0.185-0.119-0.371-0.217-0.557-0.32L71.931,228.554z"/>
    <path style={styles} d="M71.645,228.939c-0.168,0.237-0.338,0.491-0.506,0.745
    c-0.338-0.254-0.658-0.491-1.012-0.709c0.068-0.102,0.152-0.204,0.22-0.307l0.742-0.049
    C71.273,228.722,71.46,228.821,71.645,228.939z"/>
    <path style={styles} d="M66.36,250.674c0,0-4.017-2-4-2.017
    c-0.202-0.926-0.084-1.958,0.304-2.971c0.102-0.27,0.304-0.438,0.523-0.508c0.473-1.906,1.35-3.88,1.604-4.589
    c1.385-3.765,2.871-8.272,5.335-11.614c0.354,0.218,0.674,0.455,1.012,0.709c-1.923,2.936-3.376,6.263-4.642,9.503
    c-0.338,0.861-1.705,3.782-2.363,6.195c0.185,0.168,0.27,0.422,0.151,0.742c-0.304,0.794-0.438,1.586-0.27,2.278
    c0.034,0.069,0.067,0.152,0.135,0.205c0.102,0.102,0.102,0.217,0.068,0.32c0.067,0.102,1.135,1.204,1.219,1.303"/>
    <path style={styles} d="M71.087,228.62l-0.742,0.049
    c0.084-0.115,0.168-0.217,0.252-0.32C70.768,228.435,70.936,228.518,71.087,228.62z"/>
    <path style={styles} d="M69.973,228.03c0.221,0.102,0.422,0.201,0.625,0.319
    c-0.084,0.103-0.168,0.205-0.252,0.32l-0.119,0.017l-0.676-0.574C69.653,227.997,69.805,227.944,69.973,228.03z"/>
    <path style={styles} d="M70.346,228.669c-0.068,0.103-0.152,0.205-0.22,0.307
    c-0.153-0.119-0.305-0.237-0.457-0.339l0.557,0.049L70.346,228.669z"/>
    <path style={styles} d="M70.227,228.686l-0.557-0.049
    c-0.016,0-0.016-0.017-0.033-0.017c-0.203-0.135-0.203-0.373-0.086-0.508L70.227,228.686z"/>
    <path style={styles} d="M71.736,229.742c0.152,0.102,0.304,0.221,0.457,0.339
    c-2.464,3.342-3.95,7.849-5.335,11.614c-0.253,0.709-1.131,2.684-1.604,4.589c-0.219,0.069-0.422,0.238-0.523,0.508
    c-0.388,1.012,0.494,2.044,0.696,2.971l-32.702-23.364L71.736,229.742z"/>
    <path style={styles} d="M64.015,248.403c-0.168-0.692-0.034-1.484,0.27-2.278
    c0.118-0.32,0.034-0.574-0.151-0.742c0.658-2.414,2.025-5.334,2.363-6.195c1.266-3.241,2.719-6.567,4.642-9.503"/>
    <path style={styles} d="M70.126,228.976c-2.464,3.342-3.95,7.849-5.335,11.614
    c-0.253,0.709-1.131,2.684-1.604,4.589c-0.219,0.069-0.422,0.238-0.523,0.508c-0.388,1.012-0.506,2.044-0.304,2.971"/>
    <line style={styles} x1="30.659" y1="225.293" x2="62.36" y2="248.657"/>
    <polyline style={styles} points="70.227,228.686 69.669,228.636 30.659,225.293 		"/>
    </g>,
    <g id="Layer_11">
        <path style={styles} d="M150.865,198.035l4.998,37.172l0.404,2.954l-2.498,19.281
			c-0.203-1.655-0.776-3.225-1.907-4.678c-3.766-4.827-9.555-8.711-15.701-9.588c-0.133-0.017-0.235,0.017-0.32,0.102l-1.807,0.168
			l-0.86-10.043l2.262-44.367"/>
        <path style={styles} d="M138.239,193.798c0.557,0.745,1.098,1.503,1.569,2.314
			c1.926,3.225,2.84,7.084,2.772,10.984"/>
        <path style={styles} d="M136.213,193.187c0,0.033,0,0.069,0.017,0.085
			c-0.033-0.016-0.068-0.053-0.084-0.085C136.162,193.187,136.196,193.187,136.213,193.187z"/>
        <path style={styles} d="M135.336,192.072"/>
        <path style={styles} d="M65.079,249.771c3.95-5.519,9.2-9.436,15.647-11.579
			c0.186,0.017,0.338-0.053,0.473-0.151c0.101-0.037,0.203-0.086,0.303-0.119c0.776-0.238,0.457-1.401-0.32-1.2
			c-0.035,0.017-0.067,0.017-0.119,0.036c-0.944-1.724-3.292-3.261-4.659-4.372c-1.503-1.218-3.073-2.452-4.761-3.447
			c0.102-0.135,0.186-0.271,0.287-0.386l19.65-1.639l-27.195-19.06v-0.016c8.069-1.787,15.936-4.405,24.241-2.786
			c4.777,0.913,13.183,2.602,15.547,7.481c0.387,0.791,1.621,0.218,1.401-0.594c-0.17-0.606-0.44-1.18-0.795-1.688l28.394,23.839
			l0.86,10.042"/>
        <path style={styles} d="M105.576,211.94c0.219,0.812-1.014,1.385-1.401,0.594
			c-2.364-4.879-10.77-6.568-15.547-7.481c-8.305-1.619-16.172,0.999-24.241,2.786l0.49-1.045
			c8.743-2.331,17.067-4.962,26.148-2.786c2.919,0.692,8.035,1.79,11.411,4.052l0.49,0.64l1.856,1.553
			C105.135,210.76,105.406,211.333,105.576,211.94z"/>
        <path style={styles} d="M104.781,210.252l-1.856-1.553l-0.49-0.64
			C103.38,208.683,104.191,209.408,104.781,210.252z"/>
        <line style={styles} x1="104.781" y1="210.252" x2="133.175" y2="234.091"/>
        <line style={styles} x1="71.931" y1="228.554" x2="91.582" y2="226.915"/>
        <line style={styles} x1="64.386" y1="207.855" x2="91.582" y2="226.915"/>
        <path style={styles} d="M133.175,234.091"/>
        <line style={styles} x1="150.865" y1="198.723" x2="155.863" y2="235.895"/>
        <line style={styles} x1="156.267" y1="238.849" x2="155.863" y2="235.895"/>
        <path style={styles} d="M64.876,206.793l-0.49,1.045h-0.018l-0.675-0.726
			c0.034-0.017,0.051-0.033,0.085-0.033C64.149,206.978,64.504,206.896,64.876,206.793z"/>
    </g>,
    <g id="Layer_10">
    <path style={styles} d="M183.732,149.264l-31.448,46.91
    c-0.879,0.475-1.723,1.016-2.532,1.622l-12.931-6.989c-0.254-0.37-1.485,5.86-1.709-2.147L183.732,149.264z"/>
    <line style={styles} x1="183.732" y1="149.264" x2="152.284" y2="196.174"/>
    <path style={styles} d="M149.752,197.796"/>
    <path style={styles} d="M138.509,192.495l11.243,5.301
    c-2.77,2.024-5.099,4.692-6.719,7.715c-0.168-2.298-0.473-4.56-1.047-6.822C141.177,195.481,140.298,195.112,138.509,192.495z"/>
    <path style={styles} d="M150.815,198.37l0.049,0.353
    c-1.518,1.167-3.02,2.331-4.304,3.782c-1.383,1.572-2.514,3.261-3.409,5.081c-0.035-0.689-0.067-1.381-0.119-2.074
    c1.621-3.023,3.95-5.691,6.719-7.715L150.815,198.37z"/>
    <path style={styles} d="M152.284,196.174l-1.469,2.196l-1.063-0.574
    C150.562,197.189,151.405,196.649,152.284,196.174z"/>
    <path style={styles} d="M150.865,198.723"/>
    <path style={styles} d="M143.033,205.511c0.051,0.692,0.084,1.385,0.119,2.074
    c-0.457,0.896-0.86,1.807-1.215,2.77c-0.035,0.102-0.102,0.168-0.187,0.204c-0.119-0.135-0.186-0.307-0.186-0.524
    c0-0.406,0.017-0.828,0.017-1.25C141.969,207.654,142.442,206.556,143.033,205.511z"/>
    <path style={styles} d="M143.219,210.035c0.016,0.844-1.047,1.012-1.469,0.524
    c0.084-0.036,0.152-0.102,0.187-0.204c0.354-0.963,0.758-1.873,1.215-2.77C143.185,208.396,143.203,209.224,143.219,210.035z"/>
    <path style={styles} d="M141.75,210.559c-0.27,0.168-0.692-0.02-0.592-0.422
    c0.119-0.458,0.254-0.913,0.422-1.352c0,0.422-0.017,0.844-0.017,1.25C141.564,210.252,141.631,210.423,141.75,210.559z"/>
    <path style={styles} d="M136.871,192.814c0.135,0.151,0.287,0.27,0.44,0.405
    C137.108,193.2,136.922,193.068,136.871,192.814z"/>
    <path style={styles} d="M138.239,194.486c-0.101,0.036-0.236,0.017-0.338-0.033
    c-0.438-0.238-0.86-0.488-1.231-0.775c0.269,0.049,0.59-1.791,0.641-2.146C137.614,191.954,137.917,194.064,138.239,194.486z"/>
    </g>,
    <g id="Layer_9">
        <g>
            <path style={styles} d="M69.669,228.636l-39.011-3.343L9.237,177.167l60.313,50.945
				c-0.117,0.135-0.117,0.373,0.086,0.508C69.653,228.62,69.653,228.636,69.669,228.636z"/>
            <line style={styles} x1="30.659" y1="225.293" x2="9.237" y2="177.167"/>
            <polyline style={styles} points="9.237,177.167 69.551,228.112 70.227,228.686
				"/>
        </g>
    </g>,
    <g id="Layer_8">
    <g>
    <path style={styles} d="M72.083,191.261l-7.207,15.532
    c-0.372,0.103-0.727,0.185-1.097,0.287c-0.034,0-0.051,0.016-0.085,0.033l-27.397-29.946l18.788,6.481L72.083,191.261z"/>
    <line style={styles} x1="36.296" y1="177.167" x2="55.084" y2="183.648"/>
    <polyline style={styles} points="36.296,177.167 63.694,207.113 64.369,207.839
    64.386,207.839 			"/>
    </g>
</g>,
<g id="Layer_7">
    <g>
        <g>
            <path style={styles} d="M64.707,166.145c0.489,1.062,0.726,2.7,0.32,3.814
					c-2.262,6.162-9.048,5.015-14.18,3.614c-0.151-0.037-0.236-0.135-0.27-0.238c-0.084-0.27,0.135-0.623,0.489-0.541
					c2.685,0.574,6.162,1.385,8.88,0.557c3.359-1.012,5.114-4.236,3.19-7.563c-0.59-1.012-1.316-2.041-1.722-3.139
					c-1.333-3.646-0.624-7.51-1.569-11.226c-0.877-3.409-1.773-6.785-2.886-10.112c-0.051-0.135-0.051-0.25-0.034-0.353
					c0.22,0,0.422,0.017,0.642,0.017c0.338,0,0.591-0.135,0.743-0.336c0.067,0.066,0.118,0.152,0.151,0.251
					c1.857,5.285,3.022,10.669,3.342,16.257C61.989,160.708,63.255,163.056,64.707,166.145z"/>
            <path style={styles} d="M56.925,140.959c0.084-0.606,0.979-0.811,1.384-0.32
					c-0.152,0.201-0.405,0.336-0.743,0.336C57.347,140.976,57.145,140.959,56.925,140.959z"/>
            <polyline style={styles} points="50.004,172.61 50.375,173.421 55.084,183.648
					"/>
            <g>
                <path style={styles} d="M78.566,176.679l23.869,31.381
						c-3.376-2.262-8.491-3.359-11.411-4.052c-9.082-2.176-17.405,0.455-26.148,2.786l7.207-15.532l-16.999-7.613l-4.709-10.227
						l0.202-0.086c0.034,0.103,0.119,0.201,0.27,0.238c5.132,1.401,11.918,2.548,14.18-3.614c0.406-1.114,0.169-2.753-0.32-3.814
						c-1.452-3.089-2.718-5.437-2.904-8.998c-0.32-5.588-1.485-10.973-3.342-16.257L78.566,176.679z"/>
                <polyline style={styles} points="78.566,176.679 102.435,208.06 102.925,208.699
											"/>
                <line style={styles} x1="58.46" y1="140.89" x2="78.566" y2="176.679"/>
            </g>
        </g>
        <line style={styles} x1="55.084" y1="183.648" x2="72.083" y2="191.261"/>
    </g>
    </g>
];