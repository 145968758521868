import React, {Component} from 'react';
import SplitingDeer from './splittingDeer';
import AboutMe from './aboutMe';
import WorkExperience from './workExperience';
import '../../style/style.scss';
import Projects from "./projects";
import Footer from "./footer";

export default class App extends Component {

    render() {
        return (
            <div>
                <SplitingDeer/>
                <AboutMe />
                <Projects />
                <WorkExperience/>
                <Footer/>



            </div>
        );
    }
}
