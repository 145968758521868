import React from 'react';
import PropTypes from 'prop-types';
import Fade from 'react-reveal/Fade';

const NotableSkill = (props) => {

    function renderStar() {
        const stars = [];
        for(let i = 1; i <= 5; i ++) {
            if(i <= props.mark) {
               stars.push(<i className="fas fa-star full" key={`skill${i}`} />)
            } else {
                stars.push(<i className="fas fa-star empty" key={`skill${i}`} />)
            }
        }
        return stars;
    }

    return (
        <div className='col-md-4 notableSkill'>
            <Fade bottom>
            <p>{props.name}</p>
            {renderStar()}
            </Fade>
        </div>
    )
};

NotableSkill.propTypes = {
    name: PropTypes.string.isRequired,
    mark: PropTypes.number.isRequired
};

export default NotableSkill