import React, {Component} from 'react';
import TimeLinePiece from "./timeLinePeice";
import Fade from 'react-reveal/Fade';

class WorkExperience extends Component {

    constructor(props) {
        super(props);
        this.state = {
            experience: [
                {
                    company: 'the LEGO Group',
                    description: `Working as part of the Omnichannel Cart services squad within the Marketing & Channels Technology team.
Our Squad is responsible for the Cart, Order, Inventory Management and Payment services. Each of which is a standalone service with either event driven or api accessible functions to provide core business logic to the Lego.com website.`,
                    role: 'Senior Software Engineer',
                    technologies: "Jest, CDK, Amazon Dynamodb, Github Actions, React.js, Node.js, Amazon Web Services (AWS), TypeScript"
                },
                {
                    company: 'Comparison Tech',
                    description: 'Working with the insights team to provide up to date statistics within the market. Capturing and using analytics data within the energy market so create dashboard and statistics to help businesses understand the market and choose the best prices.',
                    role: 'Full Stack Engineer',
                    technologies: "Jest, Next.js, React.js, Node.js, Docker, Amazon Web Services (AWS), SQL, TypeScript, CDK"
                },
                {
                    company: 'Ageas',
                    description: 'Creating claims and internal platforms to improve users speed and performance through intuitive and easy to use UI.',
                    role: 'Developer',
                    technologies: "React, Node, Typescript, Webpack"
                },
                {
                    company: 'Clearvision',
                    description: 'Creating the ClearHub contractors platform to allow clients to easily find and recruit contractors as well as assisting on the Clearvision customer facing website.',
                    role: 'Developer',
                    technologies: "React, Node, Java, Ruby, PHP, Webpack, AWS"
                },
                {
                    company: 'My learning cloud',
                    description: 'Working on an e learning platform based in the care industry. Creating interactive learning experiences to help aid in teaching of materials.',
                    role: 'Web Developer',
                    technologies: "Angular 2, Pure JS"
                },
                {
                    company: 'A Dear Life',
                    description: 'Creating unique customer facing websites for clients to ensure the highest possible traffic growth.',
                    role: 'Web Developer',
                    technologies: "React, Node, Pure Js, Webpack"
                }
            ]
        }
    }

    renderTimeLineParts() {
      return this.state.experience.map((work, index) => {
          return <Fade bottom key={index}><TimeLinePiece work={work} /> </Fade>;
      })
    }

    render() {
        return <div className='workExperience'>
            <div className='container'>
                <div className='row'>
                    <div className='col-md-12'>
                        <Fade bottom>
                        <h2> Work </h2>
                        </Fade>
                    </div>
                </div>
                {this.renderTimeLineParts()}
            </div>
            {/*Bottom divider*/}
            <svg preserveAspectRatio="none" viewBox="0 0 100 102" height="55" width="50%" version="1.1" xmlns="http://www.w3.org/2000/svg" className='bottomDivider'>
            <path d="M0 0 L100 100 L100 0 Z" fill="#3c3c3e" stroke="#3c3c3e"/>
            </svg>
            <svg preserveAspectRatio="none" viewBox="0 0 100 102" height="55" width="50%" version="1.1" xmlns="http://www.w3.org/2000/svg" className='bottomDivider2'>
                <path d="M0 0 L100 100 L100 0 Z" fill="#3c3c3e" stroke="#3c3c3e"/>
            </svg>
        </div>
    }
}

export default WorkExperience;