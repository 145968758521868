import React from 'react';
import PropTypes from 'prop-types';


const ProjectPiece = (props) => {
    return (
        <div className='col-md-4 col-sm-6 piece'>
            <div className={'skillsLine'}>
                <h4>{props.piece.name}</h4>
                <div>
                    {props.piece.filters.map((filter) => {
                        return <span key={filter}>{filter} </span>
                    })}
                </div>
            </div>
            <img src={props.piece.image}/>
            <a href={props.piece.link} target='_blank'>Visit site</a>
        </div>
    )
};

ProjectPiece.propTypes = {
    piece: PropTypes.object.isRequired
};

export default ProjectPiece;