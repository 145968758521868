import React from 'react';
import SkillsChart from './skillsChart';
import NotableSkill from './notableSkill';
import Fade from 'react-reveal/Fade';

const AboutMe = (props) => {
    const otherSkills = [
        {name: 'Redux', mark: 5},
        {name: 'Zustand', mark: 5},
        {name: 'Jest', mark: 5},
        {name: 'Three.js', mark: 2},
        {name: 'Docker', mark: 3},
        {name: 'Git', mark: 4},
        {name: 'Webpack/parcel', mark: 4},
        {name: 'HTML5/CSS', mark: 5},
        {name: 'SCSS', mark: 5},
        {name: 'SQL', mark: 2},
        {name: 'MongoDB', mark: 3},
        {name: 'DynamoDB', mark: 3},
        {name: 'Serverless', mark: 3},
        {name: 'Illustrator', mark: 3},
        {name: 'Photoshop', mark: 2}
    ];
    return (
        <div className='aboutMe'>
            <div className='container'>
                <div className='row'>
                    <div className='col-md-12'>
                        <Fade bottom>
                            <h2> Welcome to the portfolio of Andrew Dear </h2>
                        </Fade>
                    </div>
                    <div className='col-md-6'>
                        <Fade bottom>
                            <h3> Hi, i&#39;m Andy. I am a full stack javascript developer living in Southampton,
                                UK. </h3>
                        </Fade>
                        <Fade bottom>
                            <p className='aboutMeText'> I am highly conscientious and passionate about all aspects of
                                computer science.
                                I Have over {new Date().getFullYear() - 2015} years experience working in the industry
                                and love the fast paced ever evolving nature of it.
                            </p>
                            <p className={'whiteText'}> I have strong experience working in both heavily weighted front
                                end and back end teams.
                                I feel very confident and enjoy working in both worlds. I also have a strong foundation
                                in devops development.
                                I am currently working in the Omnichannel Cart services squad at the LEGO Group.
                                Our Squad is responsible for the Cart, Order, Inventory Management and Payment services.
                                Each of which is a standalone service with either event driven or api accessible
                                functions to provide core business logic to the Lego.com website. Our services are hit
                                around 100 million times a week and process billions in orders every year.
                            </p>
                        </Fade>
                    </div>
                    <div className='col-md-6'>
                        <Fade bottom>
                            <SkillsChart/>
                        </Fade>
                    </div>
                    <div className='col-md-12'>
                        <Fade bottom>
                            <h4> Other notable skills / experience </h4>
                        </Fade>
                        <div className='row'>
                            {/*// style in a nice way that can also be added to*/}
                            {otherSkills.map(({name, mark}) => {
                                return <NotableSkill name={name} mark={mark} key={name}/>
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};


export default AboutMe;