import React from 'react';
import PropTypes from 'prop-types';

const TimeLinePiece = (props) => {
    return (
        <div className='row'>
            <div className='col-sm-4 timeLineHeading'>
                <div className='experienceHeading'>
                    <span className='timeLineDot' />
                    <p>{props.work.role}</p>
                </div>
            </div>
            <div className='col-sm-8'>
                <div className='experienceContainer'>
                    <h4>{props.work.company}</h4>
                    <p>{props.work.description}</p>
                    <p className={"technologies"}>{props.work.technologies}</p>
                </div>
            </div>
        </div>
    )
};

TimeLinePiece.propTypes = {
    work: PropTypes.object.isRequired
};

export default TimeLinePiece;