// this function will return the location of the ref passed in on the screen from the current top of the screen.

const isComponentOnScreen = (component) => {
    // create a reference and place it as a parameter and this will tell you how far from the top of the viewable screen is it if it is visible.
    const currentComponent = component.current;
    const scrollTop = document.documentElement.scrollTop;
    const screenHeight = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
    const bottomOfScreen = screenHeight + scrollTop;
    const componentHeight = Math.max(currentComponent.clientHeight, currentComponent.offsetHeight, currentComponent.scrollHeight || 0);
    const componentY = getPosition(currentComponent).y;

    // find out if the component has appeared on screen yet
    if(componentY < bottomOfScreen) {
        // see if the component has gone down so far it can no longer be seen
        if(componentY + componentHeight > scrollTop) {
            const distanceFromTop = componentY - scrollTop;
            // return the distance from the top of the screen that the element is
            return distanceFromTop;
        }
    }
    return null;
};

const getPosition = (element) => {
    let xPosition = 0;
    let yPosition = 0;

    while(element) {
        xPosition += (element.offsetLeft - element.scrollLeft + element.clientLeft);
        yPosition += (element.offsetTop - element.scrollTop + element.clientTop);
        element = element.offsetParent;
    }

    return { x: xPosition, y: yPosition };
};

export default isComponentOnScreen;