import React, {Component} from 'react';
import {headParts, rightAntlerParts, leftAnterParts} from "./svgParts";
import componentLocationToTop from '../../util/componentLocation';


class ClassComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            scrollPosition: 0
        };
        this.deerContainer = React.createRef();
    }

    componentDidMount() {
        // create a random number for each part
        for (let i = 0; i <= 47; i++) {
            const randomNumber = Math.random() * 10;
            this.setState({[`${i}`]: randomNumber < 5 ? randomNumber * 2 : randomNumber});
            if (Math.random() > 0.5) {
                this.setState({[`${i}direction`]: ''});
            } else {
                this.setState({[`${i}direction`]: '-'});
            }
        }

        window.addEventListener('scroll', this.handleScroll.bind(this));
    }

    handleScroll() {
        const distanceFromTop = componentLocationToTop(this.deerContainer) * -1;
        // animation will only fire when it is viewable
        if(distanceFromTop >= 0) {
            this.setState({scrollPosition: distanceFromTop});
        }
    }

    renderLeftAntlerParts() {
        // adds styling to each part which chages as it is using he scroll position to calculate where it should be.
        return leftAnterParts.map((part, i) => {
            const position = {
                transform: `rotate(${this.state.scrollPosition / 10 * this.state[`${i + 15}`] / 5}deg)
                 translateX(${this.state[`${i + 15}direction`]}${parseInt(`${this.state.scrollPosition * this.state[`${i + 15}`] / 4}`)}px)`,
                opacity: `${1 - this.state.scrollPosition / 500}`,
            };
            return <g key={i} style={position}>{part}</g>
        });
    }

    renderRightAntlerParts() {
        return rightAntlerParts.map((part, i) => {
            const position = {
                transform: `rotate(${this.state.scrollPosition / 10 * this.state[`${i + 30}`] / 5}deg)
                 translateX(${this.state[`${i + 30}direction`]}${parseInt(`${this.state.scrollPosition * this.state[`${i + 30}`] / 4}`)}px)`,
                opacity: `${1 - this.state.scrollPosition / 500}`,
            };
            return <g key={i} style={position}>{part}</g>
        });
    }

    renderHeadParts() {
        return headParts.map((part, i) => {
            const position = {
                transform: `rotate(${this.state.scrollPosition / 10 * this.state[`${i}`] / 5}deg)
                 translateY(${parseInt(`-${this.state.scrollPosition * this.state[`${i}`] / 4}`)}px)
                 translateX(${this.state[`${i}direction`]}${parseInt(`${this.state.scrollPosition * this.state[`${i}`] / 4}`)}px)`,
                opacity: `${1 - this.state.scrollPosition / 500}`,
            };
            return <g key={i} style={position}>{part}</g>
        })
    }

    autoScroll() {
        window.scrollBy({
            top: Math.max(document.documentElement.clientHeight, window.innerHeight || 0), // could be negative value
            left: 0,
            behavior: 'smooth'
        });
    }

    render() {

        return <div className="logoSplitContainer" ref={this.deerContainer}>
            {/*<h1 className='pageHeading'>A Dear Life</h1>*/}
            <svg version="1.1" x="0px" y="0px" width="100%"
                 height="100%" viewBox="0 0 478 454.667" style={{"enableBackground": "new 0 0 478 454.667"}}>
                {this.renderHeadParts()}
                {this.renderLeftAntlerParts()}
                {this.renderRightAntlerParts()}
            </svg>
            {this.state.scrollPosition === 0 && <i className="fas fa-angle-double-down" onClick={this.autoScroll.bind(this)}/>}
        </div>

    }
}

export default ClassComponent;